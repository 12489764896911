import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/auth/forgot-password', { email });
      setMessage(response.data.message);
      setIsSuccess(true);
      setEmail(''); // Clear form on success
    } catch (error) {
      console.error('Password reset error:', error);
      setMessage(error.response?.data?.detail || t('errorOccurred'));
      setIsSuccess(false);
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="auth-header">
          <h1>{t('forgotPassword')}</h1>
          <p>{t('enterEmailReset')}</p>
        </div>

        {message && (
          <div className={`alert ${isSuccess ? 'success-alert' : 'error-alert'}`}>
            <p>{message}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              required
              className="auth-input"
            />
          </div>

          <button type="submit" className="auth-button">
            {t('sendResetLink')}
          </button>

          <button
            type="button"
            onClick={() => navigate('/login')}
            className="auth-button secondary"
          >
            {t('backToLogin')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword; 