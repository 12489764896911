import React, { useState, useEffect, useCallback } from 'react';
import { FaEdit, FaTrash, FaReply, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
import ErrorMessage from './ErrorMessage';
import api from '../api';
import './EventQA.css';

const EventQA = ({ eventId, eventUserId }) => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [error, setError] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingAnswerId, setEditingAnswerId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [replyingToQuestionId, setReplyingToQuestionId] = useState(null);
  const { isLoggedIn, user } = useAuth();
  const { t } = useTranslation();

  const formatTimeAgo = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now - past) / 1000);
    
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };

    for (let [unit, secondsInUnit] of Object.entries(intervals)) {
      const interval = Math.floor(diffInSeconds / secondsInUnit);
      if (interval >= 1) {
        return `${interval} ${t(`eventQA.timeUnits.${unit}`, { count: interval })}`;
      }
    }
    
    return t('eventQA.timeUnits.justNow');
  };

  const fetchQuestions = useCallback(async () => {
    try {
      const response = await api.get(`/events/${eventId}/questions`);
      setQuestions(response.data.questions);
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError(t('eventQA.errors.fetchFailed'));
    }
  }, [eventId, t]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      setError(t('eventQA.errors.loginRequired'));
      return;
    }
    if (user.id === eventUserId) {
      setError(t('eventQA.errors.promoterCantAsk'));
      return;
    }
    try {
      await api.post(`/events/${eventId}/questions`, {
        content: newQuestion,
        event_id: eventId
      });
      setNewQuestion('');
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.submitFailed'));
    }
  };

  const handleEditQuestion = async (questionId, newContent) => {
    try {
      await api.put(`/questions/${questionId}`, { 
        content: newContent 
      });
      setEditingQuestionId(null);
      setEditContent('');
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.editFailed'));
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await api.delete(`/questions/${questionId}`);
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.deleteFailed'));
    }
  };

  const handleSubmitAnswer = async (questionId, answer) => {
    try {
      await api.post(`/questions/${questionId}/answers`, {
        content: answer,
        question_id: questionId
      });
      fetchQuestions();
      setReplyingToQuestionId(null);
    } catch (error) {
      setError(t('eventQA.errors.answerFailed'));
    }
  };

  const handleEditAnswer = async (answerId, newContent) => {
    try {
      await api.put(`/answers/${answerId}`, {
        content: newContent
      });
      setEditingAnswerId(null);
      setEditContent('');
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.editFailed'));
    }
  };

  const handleDeleteAnswer = async (answerId) => {
    try {
      await api.delete(`/answers/${answerId}`);
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.deleteFailed'));
    }
  };

  const handleAnswerFeedback = async (answerId, isUseful) => {
    try {
      await api.post(`/answers/${answerId}/feedback`, {
        is_useful: isUseful
      });
      fetchQuestions();
    } catch (error) {
      setError(t('eventQA.errors.feedbackFailed'));
    }
  };

  const renderAnswer = (answer, isPromoter) => (
    <div key={answer.id} className={`answer ${isPromoter ? 'promoter-answer' : 'other-answer'}`}>
      <div className="answer-header">
        <div className="user-info">
          <img 
            src={answer.user.logo ? `${process.env.REACT_APP_API_URL}${answer.user.logo}` : process.env.REACT_APP_DEFAULT_IMAGE} 
            alt="User avatar" 
            className="user-avatar"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
            }}
          />
          <span className="user-name">{answer.user.first_name} {answer.user.last_name}</span>
          {isPromoter && <span className="promoter-badge">{t('eventQA.labels.promoter')}</span>}
        </div>
        <span className="time-ago">{formatTimeAgo(answer.created_at)}</span>
      </div>
      {editingAnswerId === answer.id ? (
        <form onSubmit={(e) => {
          e.preventDefault();
          handleEditAnswer(answer.id, editContent);
        }}>
          <textarea
            value={editContent}
            onChange={(e) => setEditContent(e.target.value)}
            required
          />
          <button type="submit">{t('eventQA.buttons.save')}</button>
          <button type="button" onClick={() => setEditingAnswerId(null)}>{t('eventQA.buttons.cancel')}</button>
        </form>
      ) : (
        <p className="answer-content">{answer.content}</p>
      )}
      <div className="answer-actions">
        {isLoggedIn && user && user.id === answer.user.id && (
          <>
            <button onClick={() => {
              setEditingAnswerId(answer.id);
              setEditContent(answer.content);
            }} className="action-button">
              <FaEdit /> </button>
            <button onClick={() => handleDeleteAnswer(answer.id)} className="action-button">
              <FaTrash /> </button>
          </>
        )}
        {isLoggedIn && user && user.id !== answer.user.id && isPromoter && (
          <>
            <button onClick={() => handleAnswerFeedback(answer.id, true)} className="action-button">
              <FaThumbsUp /> ({answer.useful_count})
            </button>
            <button onClick={() => handleAnswerFeedback(answer.id, false)} className="action-button">
              <FaThumbsDown /> ({answer.not_useful_count})
            </button>
          </>
        )}
      </div>
    </div>
  );

  const renderQuestion = (q) => (
    <div key={q.question.id} className="question-container">
      <div className="question">
        <div className="question-header">
          <div className="user-info">
            <img 
              src={q.question.user.logo ? `${process.env.REACT_APP_API_URL}${q.question.user.logo}` : process.env.REACT_APP_DEFAULT_IMAGE}
              alt="User avatar" 
              className="user-avatar"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
              }}
            />
            <span className="user-name">{q.question.user.first_name} {q.question.user.last_name}</span>
          </div>
          <span className="time-ago">{formatTimeAgo(q.question.created_at)}</span>
        </div>
        {editingQuestionId === q.question.id ? (
          <form onSubmit={(e) => {
            e.preventDefault();
            handleEditQuestion(q.question.id, editContent);
          }}>
            <textarea
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              required
            />
            <button type="submit">{t('eventQA.buttons.save')}</button>
            <button type="button" onClick={() => setEditingQuestionId(null)}>{t('eventQA.buttons.cancel')}</button>
          </form>
        ) : (
          <>
            <p className="question-content">{q.question.content}</p>
            <div className="question-actions">
              {isLoggedIn && user && user.id === q.question.user.id && (
                <>
                  <button onClick={() => {
                    setEditingQuestionId(q.question.id);
                    setEditContent(q.question.content);
                  }} className="action-button">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDeleteQuestion(q.question.id)} className="action-button">
                    <FaTrash />
                  </button>
                </>
              )}
              {isLoggedIn && user && eventUserId === user.id && !q.promoter_answer && (
                <button onClick={() => setReplyingToQuestionId(q.question.id)} className="action-button">
                  <FaReply />
                </button>
              )}
            </div>
          </>
        )}
      </div>
      {(q.promoter_answer || q.other_answers.length > 0 || replyingToQuestionId === q.question.id) && (
        <div className="answers-container">
          <div className="answer-connector"></div>
          <div className="answers">
            {q.promoter_answer && renderAnswer(q.promoter_answer, true)}
            {q.other_answers && q.other_answers.map(answer => renderAnswer(answer, false))}
            {isLoggedIn && user && eventUserId === user.id && !q.promoter_answer && replyingToQuestionId === q.question.id && (
              <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmitAnswer(q.question.id, e.target.answer.value);
                e.target.answer.value = '';
              }} className="answer-form">
                <textarea name="answer" placeholder={t('eventQA.placeholders.writeReply')} required />
                <button type="submit">{t('eventQA.buttons.reply')}</button>
                <button type="button" onClick={() => setReplyingToQuestionId(null)}>{t('eventQA.buttons.cancel')}</button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="event-qa">
      {error && (
        <ErrorMessage 
          message={error} 
          type="error" 
          onClose={() => setError(null)} 
        />
      )}
      <div className="questions-list">
        {questions.map(q => renderQuestion(q))}
      </div>
      {isLoggedIn && user.id !== eventUserId ? (
        <form onSubmit={handleSubmitQuestion} className="question-form">
          <div className="user-info">
            <img src={`http://localhost:8000${user.logo}` || 'default-avatar.png'} alt="User avatar" className="user-avatar" />
            <span className="user-name">{user.first_name} {user.last_name}</span>
          </div>
          <textarea
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            placeholder={t('eventQA.placeholders.writeQuestion')}
            required
          />
          <div className="button-container">
            <button type="submit">{t('eventQA.buttons.ask')}</button>
          </div>
        </form>
      ) : isLoggedIn ? (
        <p className="info-message">{t('eventQA.labels.promoterMessage')}</p>
      ) : (
        <p className="login-message">{t('eventQA.labels.loginMessage')}</p>
      )}
    </div>
  );
};

export default EventQA;
