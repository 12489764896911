import React, { useState } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import api from '../api';
import { useTranslation } from 'react-i18next';

const EventPaymentFlow = ({ eventId, onComplete, onPayLater, eventDetails }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    
    setError(null);
    setSuccess(null);
    setLoading(true);

    if (!phoneNumber.match(/^258[0-9]{9}$/)) {
      setError(t('payment.errors.invalidPhone'));
      setLoading(false);
      return;
    }

    try {
      const paymentData = {
        event_id: eventId,
        amount: 100,
        customer_msisdn: phoneNumber
      };

      const response = await api.post('/api/v1/payments/', paymentData, {
        timeout: 30000
      });

      if (response.data.response_code === 'INS-0') {
        setError(t('payment.messages.waitingConfirmation'));
        
        if (response.data.id) {
          startPollingPaymentStatus(response.data.id);
        }
      } else if (response.data.status === 'COMPLETED') {
        setSuccess(t('payment.messages.eventCreated'));
        onComplete(response.data);
        generateReceipt(response.data, eventDetails);
      } else if (response.data.status === 'FAILED') {
        setError(response.data.response_desc || t('payment.errors.failed'));
      } else {
        setError(t('payment.errors.generic'));
      }
    } catch (error) {
      console.error('Payment error:', error);
      if (error.code === 'ECONNABORTED') {
        setError(t('payment.errors.timeout'));
      } else {
        setError(error.response?.data?.detail || t('payment.errors.generic'));
      }
    } finally {
      setLoading(false);
    }
  };

  const startPollingPaymentStatus = async (paymentId) => {
    const maxAttempts = 24;
    let attempts = 0;
    
    const pollInterval = setInterval(async () => {
      try {
        attempts++;
        const response = await api.get(`/api/v1/payments/${paymentId}`);
        
        const paymentStatus = response.data.status.toUpperCase();
        if (paymentStatus === 'COMPLETED' || paymentStatus === 'PAID') {
          clearInterval(pollInterval);
          setSuccess(t('payment.messages.eventCreated'));
          onComplete(response.data);
          generateReceipt(response.data, eventDetails);
        } else if (paymentStatus === 'FAILED') {
          clearInterval(pollInterval);
          setError(response.data.response_desc || t('payment.errors.failed'));
        } else if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setError(t('payment.errors.timeout'));
        }
      } catch (error) {
        console.error('Polling error:', error);
        if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setError(t('payment.errors.timeout'));
        }
      }
    }, 5000);

    const timeoutId = setTimeout(() => {
      clearInterval(pollInterval);
    }, 120000);

    return () => {
      clearInterval(pollInterval);
      clearTimeout(timeoutId);
    };
  };

  const generateReceipt = (paymentData, eventDetails) => {
    const receiptContent = `
    ===============================
    RECIBO DE PAGAMENTO
    ===============================
    Data: ${new Date().toLocaleString('pt-BR')}
    ID da Transação: ${paymentData.transaction_id}
    
    Evento: ${eventDetails.title}
    Valor Pago: ${eventDetails.price} MT
    Número de Telefone: ${phoneNumber}
    
    Estado: PAGO
    Método de Pagamento: M-Pesa
    ===============================
    Obrigado pelo seu pagamento!
    `;

    const blob = new Blob([receiptContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `recibo_${paymentData.transaction_id}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="payment-flow">
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {success && (
        <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
          {success}
        </Alert>
      )}

      <div className="mb-4">
        <h5>{t('payment.title')}</h5>
        <p className="text-muted">
          {t('payment.description')}
        </p>
      </div>

      {!success && (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{t('payment.phoneNumber')}</Form.Label>
            <Form.Control
              type="text"
              placeholder="258XXXXXXXXX"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={loading}
              required
            />
            <Form.Text className="text-muted">
              {t('payment.phoneHelp')}
            </Form.Text>
          </Form.Group>

          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  {t('payment.processing')}
                </>
              ) : (
                t('payment.payNow')
              )}
            </Button>
            
            <Button 
              variant="outline-secondary"
              onClick={onPayLater}
              disabled={loading}
            >
              {t('payment.payLater')}
            </Button>
          </div>
        </Form>
      )}

      {!success && (
        <div className="mt-3 text-center">
          <small className="text-muted">
            {t('payment.securePayment')}
          </small>
        </div>
      )}
    </div>
  );
};

export default EventPaymentFlow;