import React, { useState } from 'react';
import './EditProfileModal.css';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp, FaUserTie } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const EditProfileModal = ({ profile, onClose, onUpdate, onDelete }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: profile.first_name || '',
    last_name: profile.last_name || '',
    email: profile.email || '',
    short_bio: profile.short_bio || '',
    password: '',
    confirm_password: '',
    facebook: profile.facebook || '',
    twitter: profile.twitter || '',
    instagram: profile.instagram || '',
    cellphone: profile.cellphone || '',
    logo: profile.logo || '',
    role: profile.role,
    account_name: profile.account_name || '',
    current_password: '',
    new_password: '',
    confirm_new_password: '',
    date_of_birth: profile.date_of_birth || '',
    sex: profile.sex || '',
  });
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.role === 'promoter' && !formData.account_name) {
      setError(t('editProfile.errors.promoterNameRequired'));
      return;
    }

    if (formData.new_password && formData.new_password !== formData.confirm_new_password) {
      setError(t('editProfile.errors.passwordMismatch'));
      return;
    }

    setShowPasswordConfirmation(true);
  };

  const handleDeleteAccount = async () => {
    if (!profile?.id) {
      console.error('Profile or Profile ID is undefined');
      alert('Unable to delete account. Please try again later.');
      return;
    }
    try {
      const response = await fetch(`http://localhost:8000/users/${profile.id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        onDelete();
        onClose();
      } else {
        console.error('Failed to delete account');
        alert('Failed to delete account. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('An error occurred while deleting the account. Please try again.');
    }
  };

  const confirmUpdate = async () => {
    if (!formData.current_password) {
      setError('Current password is required to save changes.');
      return;
    }

    if (!profile || !profile.id) {
      console.error('Profile or profile ID is undefined');
      setError('Unable to update profile. Please try again later.');
      return;
    }

    try {
      console.log('Sending verify password request...');
      console.log('Profile ID:', profile.id);
      const verifyResponse = await fetch(`http://localhost:8000/users/verify-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: profile.id,
          password: formData.current_password
        }),
      });

      console.log('Verify password response status:', verifyResponse.status);

      if (!verifyResponse.ok) {
        const errorData = await verifyResponse.json();
        console.error('Verify password error:', errorData);
        if (verifyResponse.status === 400) {
          setError('Incorrect password. Please try again.');
        } else if (verifyResponse.status === 404) {
          setError('User not found. Please try again later.');
        } else {
          setError('An error occurred. Please try again later.');
        }
        return;
      }

      const responseData = await verifyResponse.json();
      console.log('Verify password response:', responseData);

      // Create a new object with only the fields we want to update
      const updateData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        short_bio: formData.short_bio,
        facebook: formData.facebook,
        twitter: formData.twitter,
        instagram: formData.instagram,
        cellphone: formData.cellphone,
        role: formData.role,
        account_name: formData.account_name,
        current_password: formData.current_password,
        new_password: formData.new_password,
        confirm_new_password: formData.confirm_new_password,
        date_of_birth: formData.date_of_birth,
        sex: formData.sex,
      };

      // If there's a new logo, we need to handle it separately
      if (formData.logo && formData.logo.startsWith('data:')) {
        // For now, we'll skip logo update in this JSON request
        // You might need to implement a separate endpoint for logo upload
        console.log('New logo detected. Please implement separate logo upload.');
      }

      try {
        const response = await fetch(`http://localhost:8000/users/${profile.id}/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateData),
        });

        if (response.ok) {
          const updatedProfile = await response.json();
          onUpdate(updatedProfile);
          onClose();
        } else {
          const errorData = await response.json();
          setError(errorData.detail || 'Failed to update profile. Please try again.');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        setError('An error occurred while updating the profile. Please try again.');
      }
      setShowPasswordConfirmation(false);
    } catch (error) {
      console.error('Error in confirmUpdate:', error);
      setError('An error occurred while updating the profile. Please try again.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{t('editProfile.title')}</h2>
        {error && <p className="error-message">{error}</p>}
        {showPasswordConfirmation ? (
          <div className="password-confirmation">
            <h3>{t('editProfile.passwordConfirmation.title')}</h3>
            <p>{t('editProfile.passwordConfirmation.description')}</p>
            <input
              type="password"
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
              placeholder={t('editProfile.passwordConfirmation.placeholder')}
              required
            />
            <div className="confirmation-actions">
              <button onClick={confirmUpdate}>{t('editProfile.passwordConfirmation.confirm')}</button>
              <button onClick={() => setShowPasswordConfirmation(false)}>
                {t('editProfile.passwordConfirmation.cancel')}
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder={t('editProfile.fields.firstName')}
              required
            />
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder={t('editProfile.fields.lastName')}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t('editProfile.fields.email')}
              required
            />
            <textarea
              name="short_bio"
              value={formData.short_bio}
              onChange={handleChange}
              placeholder={t('editProfile.fields.shortBio')}
            ></textarea>
            <div className="social-media-input">
              <FaFacebook />
              <input
                type="text"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                placeholder={t('editProfile.fields.facebook')}
              />
            </div>
            <div className="social-media-input">
              <FaTwitter />
              <input
                type="text"
                name="twitter"
                value={formData.twitter}
                onChange={handleChange}
                placeholder={t('editProfile.fields.twitter')}
              />
            </div>
            <div className="social-media-input">
              <FaInstagram />
              <input
                type="text"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                placeholder={t('editProfile.fields.instagram')}
              />
            </div>
            <div className="social-media-input">
              <FaWhatsapp />
              <input
                type="text"
                name="cellphone"
                value={formData.cellphone}
                onChange={handleChange}
                placeholder={t('editProfile.fields.whatsapp')}
              />
            </div>
            <div className="logo-upload">
              <label htmlFor="logo-input">{t('editProfile.fields.logo.label')}</label>
              <input
                id="logo-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              {formData.logo && (
                <img src={formData.logo} alt="Logo Preview" className="logo-preview" />
              )}
            </div>
            {profile.role !== 'promoter' && (
              <div className="promoter-upgrade">
                <label>
                  <input
                    type="checkbox"
                    name="role"
                    checked={formData.role === 'promoter'}
                    onChange={(e) => setFormData({ 
                      ...formData, 
                      role: e.target.checked ? 'promoter' : 'user',
                      account_name: e.target.checked ? formData.account_name : ''
                    })}
                  />
                  {t('editProfile.fields.promoter.upgrade')}
                </label>
              </div>
            )}
            {formData.role === 'promoter' && (
              <div className="social-media-input">
                <FaUserTie />
                <input
                  type="text"
                  name="account_name"
                  value={formData.account_name}
                  onChange={handleChange}
                  placeholder={t('editProfile.fields.promoter.accountName')}
                  required
                />
              </div>
            )}
      
            <input
              type="password"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
              placeholder={t('editProfile.fields.password.new')}
            />
            {formData.new_password && (
              <input
                type="password"
                name="confirm_new_password"
                value={formData.confirm_new_password}
                onChange={handleChange}
                placeholder={t('editProfile.fields.password.confirm')}
                required
              />
            )}
            <div className="form-group">
              <label htmlFor="date_of_birth">{t('editProfile.fields.dateOfBirth')}</label>
              <input
                type="date"
                id="date_of_birth"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="sex">{t('editProfile.fields.sex.label')}</label>
              <select
                id="sex"
                name="sex"
                value={formData.sex}
                onChange={handleChange}
              >
                <option value="">{t('editProfile.fields.sex.select')}</option>
                <option value="male">{t('editProfile.fields.sex.male')}</option>
                <option value="female">{t('editProfile.fields.sex.female')}</option>
                <option value="other">{t('editProfile.fields.sex.other')}</option>
                <option value="prefer_not_to_say">{t('editProfile.fields.sex.preferNotToSay')}</option>
              </select>
            </div>
            <div className="modal-actions">
              <button type="submit">{t('editProfile.buttons.save')}</button>
              <button type="button" onClick={onClose}>{t('editProfile.buttons.cancel')}</button>
            </div>
          </form>
        )}
        <div className="delete-account-section">
          <button onClick={() => setShowDeleteConfirmation(true)} className="delete-account-btn">
            {t('editProfile.buttons.deleteAccount')}
          </button>
        </div>
        {showDeleteConfirmation && (
          <div className="delete-confirmation">
            <p>{t('editProfile.deleteConfirmation.message')}</p>
            <button onClick={handleDeleteAccount} className="confirm-delete-btn">
              {t('editProfile.deleteConfirmation.confirm')}
            </button>
            <button onClick={() => setShowDeleteConfirmation(false)} className="cancel-delete-btn">
              {t('editProfile.deleteConfirmation.cancel')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfileModal;