import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import api from '../api'; // Import the api instance
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faCalendarAlt,  faStar, faChevronRight, faMapMarkerAlt ,faUser, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import EditProfileModal from '../components/EditProfileModal';
import EventsModal from '../components/EventsModal';
import ReviewsModal from '../components/ReviewsModal';
import './Profile.css';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const { id } = useParams();
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const navigate = useNavigate();
  const [promoterEvents, setPromoterEvents] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get(`/users/${id}/profile`);
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    fetchProfile();
  }, [id]);

  useEffect(() => {
    const fetchPromoterData = async () => {
      if (profile && profile.role === 'promoter') {
        try {
          const response = await api.get(`/promoter/${profile.id}/events`);
          setPromoterEvents(response.data);
        } catch (error) {
          console.error('Error fetching promoter events:', error);
        }
      }
    };
    
    if (profile) {
      fetchPromoterData();
    }
  }, [profile]);

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setShowEditModal(false);
  };

  if (!profile) return <div>Loading...</div>;

  const isOwnProfile = isLoggedIn && user && user.id === id;

  const renderStarRating = (rating) => {
    return (
      <div className="review-rating">
        {[...Array(5)].map((_, i) => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            className={i < rating ? 'filled-star' : 'empty-star'}
          />
        ))}
      </div>
    );
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await api.delete(`/users/${user.id}`);
      if (response.status === 200 || response.status === 204) {
        logout();
        navigate('/');
      } else {
        console.error('Failed to delete account');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };
  
  const renderContent = () => {
    if (profile.role === 'promoter') {
      return (
        <div className="promoter-content">
          <div className="promoter-portfolio">
            <div className="portfolio-header">
              <div className="portfolio-stats">
                <div className="stat-item">
                  <span className="stat-value">{promoterEvents?.length || 0}</span>
                  <span className="stat-label">{t('publicProfile.stats.events')}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-value">
                    {promoterEvents?.reduce((acc, event) => acc + (event.interest_count || 0), 0)}
                  </span>
                  <span className="stat-label">{t('publicProfile.stats.interests')}</span>
                </div>
                <div className="stat-item">
                  <span className="stat-value">
                    {promoterEvents?.length > 0 
                      ? (promoterEvents.reduce((acc, event) => 
                          acc + (event.average_rating || 0), 0) / promoterEvents.length
                        ).toFixed(2)
                      : t('publicProfile.stats.notAvailable')}
                  </span>
                  <span className="stat-label">{t('publicProfile.stats.avgRating')}</span>
                </div>
              </div>
            </div>

            {promoterEvents && promoterEvents.length > 0 ? (
              <div className="portfolio-grid">
                {promoterEvents.map(event => (
                  <Link to={`/event/${event.event.id}`} key={event.event.id} className="portfolio-item">
                    <div className="portfolio-image">
                      <img 
                        src={event.event.image ? `${process.env.REACT_APP_API_URL}${event.event.image}` : process.env.REACT_APP_DEFAULT_IMAGE} 
                        alt={event.event.title} 
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                        }}
                      />
                      <div className="portfolio-overlay">
                        <div className="event-stats">
                          <span className="stat">
                            <FontAwesomeIcon icon={faStar} /> 
                            {event.average_rating ? event.average_rating.toFixed(2) : 'N/A'}
                          </span>
                          <span className="stat">
                            <FontAwesomeIcon icon={faUser} /> 
                            {event.event.interest_count || 0}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="portfolio-details">
                      <h3>{event.event.title}</h3>
                      <div className="event-meta">
                        <p className="event-date">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          {new Date(event.event.date).toLocaleDateString()}
                        </p>
                        <p className="event-location">
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                          {event.event.location}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="no-events">
                <p>No events published yet.</p>
                {isOwnProfile && (
                  <Link to="/dashboard/events" className="create-event-btn">
                    Create Your First Event
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      // Regular user layout
      return (
        <div className="regular-user-content">
          <section className="interested-events">
            <h2>{t('publicProfile.sections.interestedEvents')}</h2>
            {profile.attended_events && profile.attended_events.length > 0 ? (
              <>
                <ul>
                  {profile.attended_events.slice(0, 5).map(event => (
                    <li key={event.id}>
                      <Link to={`/event/${event.id}`}>
                        <div className="event-item">
                          <img 
                            src={`${process.env.REACT_APP_API_URL}${event.image}`} 
                            alt={event.title} 
                            className="event-thumbnail"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
                            }}
                          />
                          <div className="event-details">
                            <h3>{event.title}</h3>
                            <p>{new Date(event.date).toLocaleDateString()}</p>
                            <p>{event.location}</p>
                            <p>{event.description.substring(0, 100)}...</p>
                            <p>{t('publicProfile.eventDetails.category')}: {event.category}</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                {profile.attended_events.length > 5 && (
                  <button className="view-more-btn" onClick={() => setShowEventsModal(true)}>
                    {t('publicProfile.sections.viewMore')} <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                )}
              </>
            ) : (
              <p>{t('publicProfile.sections.noEvents')}</p>
            )}
          </section>

          <section className="user-reviews">
            <h2>{t('publicProfile.sections.reviews')}</h2>
            {profile.reviews && profile.reviews.length > 0 ? (
              <>
                <ul>
                  {profile.reviews.slice(0, 5).map(review => (
                    <li key={review.id}>
                      <Link to={`/event/${review.event_id}`}>
                        <h3>{review.event_title}</h3>
                        {renderStarRating(review.rating)}
                        <p>{review.review_text}</p>
                        <p className="review-date">
                          {t('publicProfile.eventDetails.reviewedOn')}: {new Date(review.created_at).toLocaleDateString()}
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
                {profile.reviews.length > 5 && (
                  <button className="view-more-btn" onClick={() => setShowReviewsModal(true)}>
                    {t('publicProfile.sections.viewMore')} <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                )}
              </>
            ) : (
              <p>{t('publicProfile.sections.noReviews')}</p>
            )}
          </section>
        </div>
      );
    }
  };
  return (
    <div className="profile-page">
      <div className="profile-header">
        <div className="profile-logo-container">
          {profile.logo ? (
            <img 
              src={`${process.env.REACT_APP_API_URL}${profile.logo}`} 
              alt="User Logo" 
              className="profile-logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
              }}
            />
          ) : (
            <div className="profile-initials">
              {profile.first_name[0]}{profile.last_name[0]}
            </div>
          )}
        </div>
        <div className="profile-info">
          <h1>
            {profile.first_name} {profile.last_name}
            {profile.verified && (
              <span className="verified-badge" title={t('verifiedUser')}>
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
            )}
          </h1>
          {profile.short_bio && <p className="bio">{profile.short_bio}</p>}
          <div className="user-details">
            <p><FontAwesomeIcon icon={faEnvelope} /> {profile.email}</p>
            {profile.cellphone && <p><FontAwesomeIcon icon={faPhone} /> {profile.cellphone}</p>}
            <p><FontAwesomeIcon icon={faCalendarAlt} /> {t('publicProfile.userInfo.joined')}: {new Date(profile.date_joined).toLocaleDateString('en-GB')}</p>
          </div>
          <div className="social-links">
            {profile.facebook && (
              <a href={profile.facebook} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            )}
            {profile.twitter && (
              <a href={profile.twitter} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            )}
            {profile.instagram && (
              <a href={profile.instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
          </div>
          {isOwnProfile && (
            <button className="edit-profile-btn" onClick={() => setShowEditModal(true)}>
              {t('publicProfile.userInfo.editProfile')}
            </button>
          )}
        </div>
      </div>
      
      <div className="profile-content">
        {renderContent()}
      </div>

      {showEditModal && profile && (
        console.log('Profile data:', profile),
        <EditProfileModal
          profile={profile}
          onClose={() => setShowEditModal(false)}
          onUpdate={handleProfileUpdate}
          onDelete={handleDeleteAccount}
        />
      )}
      {showEventsModal && (
        <EventsModal
          events={profile.attended_events}
          onClose={() => setShowEventsModal(false)}
        />
      )}
      {showReviewsModal && (
        <ReviewsModal
          reviews={profile.reviews}
          onClose={() => setShowReviewsModal(false)}
        />
      )}
    </div>
  );
};

export default Profile;
