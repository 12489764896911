import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import api from '../api';
import './EventPreviewPage.css';

const EventPreviewPage = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEventPreview = async () => {
      try {
        const response = await api.get(`/event/${id}/preview`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event preview:', error);
      }
    };

    fetchEventPreview();
  }, [id]);

  if (!event) {
    return <p>Loading event preview...</p>;
  }

  const fullImageUrl = event.image 
    ? `${process.env.REACT_APP_API_URL}${event.image}`
    : process.env.REACT_APP_DEFAULT_IMAGE;

  return (
    <div className="event-preview-page">
      <Helmet>
        <title>{event.title}</title>
        <meta property="og:title" content={event.title} />
        <meta property="og:description" content="Check out this event!" />
        <meta property="og:image" content={fullImageUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <img 
        src={fullImageUrl}
        alt={event.title} 
        className="event-preview-image"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
        }}
      />
      <h1>{event.title}</h1>
      <Link to={`/event/${id}`} className="view-event-button">
        View Event Details
      </Link>
    </div>
  );
};

export default EventPreviewPage;