import React, { useState } from 'react';
import './FiltersPanel.css';
import { useTranslation } from 'react-i18next';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

const categories = [
  'Music', 'Art', 'Food', 'Sports', 'Theater', 
  'Technology', 'Education', 'Business', 'Health', 'Other'
];

const FiltersPanel = ({ selectedCategories, onFilter, selectedDateRange, onDateChange }) => {
  const { t } = useTranslation();
  const [selectedQuickDateFilter, setSelectedQuickDateFilter] = useState(null);

  const handleQuickDateFilter = (filter) => {
    const today = new Date();

    if (selectedQuickDateFilter === filter) {
      setSelectedQuickDateFilter(null);
      onDateChange({ startDate: null, endDate: null });
      return;
    }

    let newDateRange = { startDate: null, endDate: null };

    switch (filter) {
      case 'today':
        newDateRange = { startDate: today, endDate: today };
        break;
      case 'tomorrow':
        const tomorrow = addDays(today, 1);
        newDateRange = { startDate: tomorrow, endDate: tomorrow };
        break;
      case 'thisWeek':
        newDateRange = {
          startDate: startOfWeek(today, { weekStartsOn: 1 }),
          endDate: endOfWeek(today, { weekStartsOn: 1 }),
        };
        break;
      case 'nextWeek':
        const nextWeekStart = addWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1);
        const nextWeekEnd = endOfWeek(nextWeekStart, { weekStartsOn: 1 });
        newDateRange = { startDate: nextWeekStart, endDate: nextWeekEnd };
        break;
      case 'thisMonth':
        newDateRange = {
          startDate: startOfMonth(today),
          endDate: endOfMonth(today),
        };
        break;
      case 'nextMonth':
        const nextMonthStart = startOfMonth(addMonths(today, 1));
        const nextMonthEnd = endOfMonth(nextMonthStart);
        newDateRange = { startDate: nextMonthStart, endDate: nextMonthEnd };
        break;
      default:
        break;
    }

    setSelectedQuickDateFilter(filter);
    onDateChange({
      startDate: newDateRange.startDate.toISOString().split('T')[0],
      endDate: newDateRange.endDate.toISOString().split('T')[0],
    });
  };

  const dateFilters = [
    'today', 'tomorrow', 'thisWeek', 
    'nextWeek', 'thisMonth', 'nextMonth'
  ];

  return (
    <div className="filters-panel">
      {/* Category Filter */}
      <div className="filters-row category-row">
        <div className="category-buttons">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategories.includes(category) ? 'selected' : ''}`}
              onClick={() => onFilter(category)}
            >
              {t(`categories.${category}`)}
            </button>
          ))}
        </div>
      </div>

      {/* Date Filter */}
      <div className="filters-row date-row">
        <div className="date-filter-buttons">
          {dateFilters.map((filter) => (
            <button
              key={filter}
              className={`date-filter-button ${selectedQuickDateFilter === filter ? 'selected' : ''}`}
              onClick={() => handleQuickDateFilter(filter)}
            >
              {t(`dateFilters.${filter}`)}
            </button>
          ))}
        </div>
      </div>

      {/* Date Input */}
      <div className="date-input">
        <input
          type="date"
          value={selectedDateRange?.startDate || ''}
          onChange={(e) =>
            onDateChange({
              startDate: e.target.value,
              endDate: e.target.value,
            })
          }
          className="date-filter"
          aria-label={t('selectDate')}
        />
      </div>
    </div>
  );
};

export default FiltersPanel;
