import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import Navbar from './components/Navbar';
import EventDetailsPage from './pages/EventDetailsPage';
import PromoterDashboard from './pages/PromoterDashboard';
import EventListingsPage from './pages/EventListingsPage';
import BrandHeader from './components/BrandHeader'; // Import the BrandHeader
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoute from './pages/ProtectedRoute';
import Profile from './pages/Profile';
import Footer from './components/Footer'; // Import Footer
import EventPreviewPage from './pages/EventPreviewPage';
import AdminDashboard from './pages/AdminDashboard';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Help from './pages/Help';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import About from './pages/About';
import { AuthProvider, useAuth } from './AuthContext';  // Import AuthProvider
import Loader from './components/Loader';

// Move route configuration to a separate object
const ROUTE_CONFIG = {
  HOME: '/',
  EVENTS: '/events',
  EVENT_DETAILS: '/event/:id',
  ADMIN_DASHBOARD: '/admin/dashboard',
  PROMOTER_DASHBOARD: '/dashboard/events',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  PROFILE: '/profile/:id',
  USER_PROFILE: '/user/:id',
  EVENT_PREVIEW: '/event/:id/preview',
  HELP: '/help',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  ABOUT: '/about'
};

// Role-based redirects
const ROLE_REDIRECTS = {
  admin: '/admin/dashboard',
  promoter: '/dashboard/events',
  default: '/'
};

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const { loading: authLoading } = useAuth();

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  if (authLoading) {
    return <Loader size="large" />;
  }

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <BrandHeader />
          <Navbar onSearch={handleSearch} />
          <div className="main-content">
            <Routes>
              <Route path={ROUTE_CONFIG.HOME} element={<EventListingsPage searchQuery={searchTerm} />} />
              <Route path={ROUTE_CONFIG.EVENTS} element={<EventListingsPage searchQuery={searchTerm} />} />
              <Route path={ROUTE_CONFIG.EVENT_DETAILS} element={<EventDetailsPage />} />
              <Route 
                path={ROUTE_CONFIG.ADMIN_DASHBOARD}
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <AdminDashboard />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path={ROUTE_CONFIG.PROMOTER_DASHBOARD}
                element={
                  <ProtectedRoute allowedRoles={['promoter']}>
                    <PromoterDashboard />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path={ROUTE_CONFIG.DASHBOARD}
                element={
                  <ProtectedRoute>
                    {({ user }) => {
                      const redirectPath = ROLE_REDIRECTS[user.role] || ROLE_REDIRECTS.default;
                      return <Navigate to={redirectPath} replace />;
                    }}
                  </ProtectedRoute>
                } 
              />
              <Route path={ROUTE_CONFIG.LOGIN} element={<Login />} />
              <Route path={ROUTE_CONFIG.FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route path={ROUTE_CONFIG.RESET_PASSWORD} element={<ResetPassword />} />
              <Route path={ROUTE_CONFIG.REGISTER} element={<Register />} />
              <Route path={ROUTE_CONFIG.PROFILE} element={<Profile />} />
              <Route path={ROUTE_CONFIG.USER_PROFILE} element={<Profile />} />
              <Route path={ROUTE_CONFIG.EVENT_PREVIEW} element={<EventPreviewPage />} />
              <Route path={ROUTE_CONFIG.HELP} element={<Help />} />
              <Route path={ROUTE_CONFIG.PRIVACY} element={<Privacy />} />
              <Route path={ROUTE_CONFIG.TERMS} element={<Terms />} />
              <Route path={ROUTE_CONFIG.ABOUT} element={<About />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
