import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faFire, faStar, faHistory, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FiltersPanel from '../components/FiltersPanel';
import EventCard from '../components/EventCard';
import './EventListingsPage.css';
import SearchBar from '../components/SearchBar';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../components/ErrorMessage';
import Loader from '../components/Loader';
import api from '../api';

const PROVINCES = [
  'Maputo Cidade',
  'Maputo Província',
  'Gaza',
  'Inhambane',
  'Sofala',
  'Manica',
  'Tete',
  'Zambezia',
  'Nampula',
  'Cabo Delgado',
  'Niassa'
];

const EventListingsPage = ({ searchQuery }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: '', endDate: '' });
  const [sortOrder, setSortOrder] = useState('date-asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [userLocation, setUserLocation] = useState(null);
  const [cachedData, setCachedData] = useState({});
  const fetchTimeoutRef = useRef(null);
  const [shouldFetchNearMe, setShouldFetchNearMe] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState('');

  const getUserLocation = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setShouldFetchNearMe(true);
        },
        (error) => {
          console.error("Error getting user location:", error);
          alert(t('locationError'));
        }
      );
    } else {
      alert(t('locationNotSupported'));
    }
  }, [t]);

  const handleSearch = useCallback((query) => {
    setSearchTerm(query);
    setCachedData({}); // Clear cache when search changes
    setCurrentPage(1); // Reset to first page when searching
  }, []);

  const fetchEvents = useCallback(async (page = 1) => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        size: '9',
        sortOrder: sortOrder
      });

      if (selectedCategories.length > 0) params.append('categories', selectedCategories.join(','));
      if (selectedDateRange.startDate) {
        params.append('start_date', selectedDateRange.startDate);
        params.append('end_date', selectedDateRange.endDate || selectedDateRange.startDate);
      }
      if (searchTerm) params.append('search', searchTerm);
      params.append('tab', activeTab);
      if (activeTab === 'nearMe' && userLocation) {
        params.append('latitude', userLocation.latitude.toString());
        params.append('longitude', userLocation.longitude.toString());
      }
      if (selectedProvince) params.append('city', selectedProvince);

      const response = await api.get(`/events?${params.toString()}`);
      const data = response.data;

      setEvents(data.events);
      setTotalPages(data.totalPages);
      setCachedData(prevCache => ({
        ...prevCache,
        [activeTab]: { events: data.events, totalPages: data.totalPages }
      }));
    } catch (error) {
      console.error('Error fetching events:', error);
      setError(t('errorFetchingEvents'));
    } finally {
      setLoading(false);
    }
  }, [selectedCategories, selectedDateRange, searchTerm, sortOrder, activeTab, userLocation, selectedProvince, t]);

  useEffect(() => {
    if (activeTab === 'nearMe' && !userLocation) {
      getUserLocation();
    }
  }, [activeTab, userLocation, getUserLocation]);

  useEffect(() => {
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }
    fetchTimeoutRef.current = setTimeout(() => {
      if (activeTab === 'nearMe') {
        if (userLocation && shouldFetchNearMe) {
          fetchEvents(currentPage);
          setShouldFetchNearMe(false);
        }
      } else if (cachedData[activeTab]) {
        setEvents(cachedData[activeTab].events);
        setTotalPages(cachedData[activeTab].totalPages);
        setLoading(false);
      } else {
        fetchEvents(currentPage);
      }
    }, 300);  // 300ms debounce

    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [activeTab, currentPage, fetchEvents, cachedData, userLocation, shouldFetchNearMe]);

  // New useEffect for handling "nearMe" tab
  useEffect(() => {
    if (activeTab === 'nearMe' && userLocation) {
      fetchEvents(currentPage);
    }
  }, [activeTab, userLocation, currentPage, fetchEvents]);

  const handleFilter = useCallback((category) => {
    setSelectedCategories(prev => 
      prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]
    );
    setCachedData({});  // Clear cache when filters change
  }, []);

  const handleDateChange = useCallback((range) => {
    setSelectedDateRange(range);
    setCachedData({});  // Clear cache when date range changes
  }, []);

  const handleSortChange = useCallback((e) => {
    setSortOrder(e.target.value);
    setCachedData({});  // Clear cache when sort order changes
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    if (tab === 'nearMe') {
      setCachedData(prevCache => ({ ...prevCache, nearMe: null })); // Clear cache for nearMe tab
      if (!userLocation) {
        getUserLocation();
      } else {
        setShouldFetchNearMe(true);
      }
    }
    setCachedData({}); // Clear cache when changing tabs
  }, [getUserLocation, userLocation]);

  const handleProvinceChange = useCallback((e) => {
    setSelectedProvince(e.target.value);
    setCurrentPage(1);
    setCachedData({}); // Clear cache when province changes
  }, []);

  return (
    <div className="event-listings-page">
      {error && (
        <ErrorMessage 
          message={error} 
          type="error" 
          onClose={() => setError(null)} 
        />
      )}
      <div className="search-container">
        <SearchBar onSearch={handleSearch} />
      </div>

      <div className="filters-container">
        <button 
          className="toggle-filters-btn"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FontAwesomeIcon icon={faFilter} />
        {showFilters ? t('hideFilters') : t('showFilters')}
      </button>
        
        <div className={`filters-wrapper ${showFilters ? 'show' : ''}`}>
          <FiltersPanel 
            selectedCategories={selectedCategories}
            onFilter={handleFilter}
            selectedDateRange={selectedDateRange}
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      {/* Updated Tabs Section */}
      <div className="tabs-container">
        <button 
          className={`tab ${activeTab === 'upcoming' ? 'active' : ''}`}
          onClick={() => handleTabChange('upcoming')}
        >
          <FontAwesomeIcon icon={faCalendarAlt} /> {t('upcoming')}
        </button>
        <button 
          className={`tab ${activeTab === 'nearMe' ? 'active' : ''}`}
          onClick={() => handleTabChange('nearMe')}
        >
          <FontAwesomeIcon icon={faMapMarkerAlt} /> {t('nearMe')}
        </button>
        <button 
          className={`tab ${activeTab === 'hot' ? 'active' : ''}`}
          onClick={() => handleTabChange('hot')}
        >
          <FontAwesomeIcon icon={faFire} /> {t('hot')}
        </button>
        <button 
          className={`tab ${activeTab === 'popular' ? 'active' : ''}`}
          onClick={() => handleTabChange('popular')}
        >
          <FontAwesomeIcon icon={faStar} /> {t('popular')}
        </button>
        <button 
          className={`tab ${activeTab === 'past' ? 'active' : ''}`}
          onClick={() => handleTabChange('past')}
        >
          <FontAwesomeIcon icon={faHistory} /> {t('past')}
        </button>
      </div>

      <div className="sort-options">
        <select id="sort" value={sortOrder} onChange={handleSortChange}>
          <option value="date-asc">{t('sortDateAsc')}</option>
          <option value="date-desc">{t('sortDateDesc')}</option>
          <option value="name-asc">{t('sortNameAsc')}</option>
          <option value="name-desc">{t('sortNameDesc')}</option>
        </select>
        
        <select 
          id="province" 
          value={selectedProvince} 
          onChange={handleProvinceChange}
          className="province-select"
        >
          <option value="">{t('allProvinces')}</option>
          {PROVINCES.map(province => (
            <option key={province} value={province}>
              {province}
            </option>
          ))}
        </select>
      </div>

      <div className="content">
        <div className="event-listings">
          {loading ? (
            <Loader />
          ) : events && events.length > 0 ? (
            events.map((event) => (
              <EventCard key={event.id} event={event} />
            ))
          ) : (
            <div className="no-events-container">
              <FontAwesomeIcon icon={faSearch} className="no-events-icon" />
              <h3>{t('noEventsFound')}</h3>
              <p className="no-events-subtitle">
                {activeTab === 'nearMe' 
                  ? t('noEventsNearby')
                  : t('tryAdjustingFilters')}
              </p>
              {(selectedCategories.length > 0 || selectedDateRange.startDate || searchTerm) && (
                <button 
                  className="clear-filters-btn"
                  onClick={() => {
                    setSelectedCategories([]);
                    setSelectedDateRange({ startDate: '', endDate: '' });
                    setSearchTerm('');
                    handleSearch('');
                  }}
                >
                  {t('clearFilters')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Pagination controls */}
      {totalPages > 1 && (
        <div className="pagination">
          {/* Previous button */}
          <button
            className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {t('previous')}
          </button>

          {/* Page numbers */}
          {Array.from({ length: Math.min(6, totalPages) }, (_, index) => {
            const pageNumber = Math.max(1, currentPage - 2) + index;
            if (pageNumber > totalPages) return null;
            return (
              <button
                key={pageNumber}
                className={`pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            );
          })}

          {/* Next button */}
          <button
            className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {t('next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default EventListingsPage;
