import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Constants for storage keys and error messages
const AUTH_CONSTANTS = {
  TOKEN_KEY: 'access_token',
  USER_DATA_KEY: 'user_data',
  ERROR_MESSAGES: {
    CONTEXT: 'useAuth must be used within an AuthProvider',
    INACTIVE_ACCOUNT: 'accountInactive',
    LOGIN_ERROR: 'loginError'
  }
};

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const clearAllUserData = useCallback(() => {
    localStorage.removeItem(AUTH_CONSTANTS.TOKEN_KEY);
    localStorage.removeItem(AUTH_CONSTANTS.USER_DATA_KEY);
    sessionStorage.clear();
    setUser(null);
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(AUTH_CONSTANTS.TOKEN_KEY);
    const userData = localStorage.getItem(AUTH_CONSTANTS.USER_DATA_KEY);
    
    if (token && userData) {
      try {
        const parsedUserData = JSON.parse(userData);
        if (parsedUserData.is_active) {
          clearAllUserData();
          return;
        }
        setUser(parsedUserData);
        setIsLoggedIn(true);
      } catch (error) {
        console.error('Error parsing user data:', error);
        clearAllUserData();
      }
    }
    setLoading(false);
  }, [clearAllUserData]);

  const login = (token, userData) => {
    return new Promise((resolve, reject) => {
      console.log('Login attempt:', { token, userData });
      
      if (!userData.is_active) {
        console.log('User inactive, rejecting');
        reject(new Error(t(AUTH_CONSTANTS.ERROR_MESSAGES.INACTIVE_ACCOUNT)));
        return;
      }
      
      try {
        console.log('Storing token:', token);
        localStorage.setItem(AUTH_CONSTANTS.TOKEN_KEY, token);
        console.log('Token stored, checking:', localStorage.getItem(AUTH_CONSTANTS.TOKEN_KEY));
        
        localStorage.setItem(AUTH_CONSTANTS.USER_DATA_KEY, JSON.stringify(userData));
        setUser(userData);
        setIsLoggedIn(true);
        console.log('Login successful');
        resolve();
      } catch (error) {
        console.error('Login error:', error);
        reject(new Error(t(AUTH_CONSTANTS.ERROR_MESSAGES.LOGIN_ERROR)));
      }
    });
  };

  const logout = useCallback(() => {
    return new Promise((resolve) => {
      console.log('Logging out...');
      clearAllUserData();
      console.log('Logout complete');
      resolve();
    });
  }, [clearAllUserData]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(AUTH_CONSTANTS.ERROR_MESSAGES.CONTEXT);
  }
  return context;
};
