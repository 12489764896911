import axios from 'axios';

// Create an axios instance with only essential environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.soevento.com',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 5000, // This can be hardcoded as it's a reasonable default
});

// Add this for debugging
console.log('API Base URL:', process.env.REACT_APP_API_URL);

// Special config for payment endpoints
api.interceptors.request.use((config) => {
  if (config.url.includes('/payments/')) {
    config.timeout = 30000; // 30 seconds for payment endpoints
  }
  return config;
});

// Request interceptor to add the Authorization header
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token'); // No need for env var
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.error('Unauthorized! Redirecting to login...');
          localStorage.removeItem('access_token');
          window.location.href = '/login';
          break;
        case 403:
          console.error('Forbidden! You do not have the necessary permissions.');
          break;
        default:
          console.error(`Error ${error.response.status}:`, error.response.data);
      }
    } else if (error.request) {
      console.error('No response received from the server:', error.request);
    } else {
      console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
