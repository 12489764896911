import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from './locales/en/translations.json';
import pt from './locales/pt/translations.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      pt: {
        translation: pt,
      },
    },
    lng: 'pt', // Set default language to Portuguese
    fallbackLng: 'en', // Fallback to English
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n; 