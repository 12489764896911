import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { FaBell, FaCircle, FaHeart, FaQuestion, FaComment, FaStar, FaThumbsUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import api from '../api';
import './Navbar.css';

const Navbar = () => {
  const { t } = useTranslation();
  const { isLoggedIn, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);
// Add this function to calculate time ago
const timeAgo = (dateString) => {
  const now = new Date();
  const past = new Date(dateString);
  const diffInSeconds = Math.floor((now - past) / 1000);
  
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60
  };

  for (let [unit, secondsInUnit] of Object.entries(intervals)) {
    const interval = Math.floor(diffInSeconds / secondsInUnit);
    if (interval >= 1) {
      return `${interval} ${t(`eventQA.timeUnits.${unit}`, { count: interval })}`;
    }
  }
  
  return t('eventQA.timeUnits.justNow');
};

// Add this helper function at the top of the file
const getInitials = (name) => {
  if (!name) return '';
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};



  // Function to get display name based on user role
  const getDisplayName = () => {
    if (user && user.role === 'promoter' && user.account_name) {
      return user.account_name; // Show account name for promoters
    } else if (user) {
      return `${user.first_name} ${user.last_name}`; // Show first and last name for users or promoters without account name
    }
    return null; // Return null if no user is logged in
  };

  const handleLogout = useCallback(async () => {
    await logout();
    // Force a re-render by updating a state or using a key on a parent component
    navigate('/', { replace: true });
  }, [logout, navigate]);

  const fetchNotifications = useCallback(async () => {
    if (isLoggedIn) {
      try {
        const response = await api.get('/notifications');
        setNotifications(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
   
    fetchNotifications();
    
    const intervalId = setInterval(() => {
      fetchNotifications();
    }, 60000);
    
    return () => clearInterval(intervalId);
  }, [fetchNotifications, isLoggedIn]);

  useEffect(() => {
  }, [notifications]);

  const markAsRead = useCallback(async (notificationId) => {
    try {
      const response = await api.post(`/notifications/${notificationId}/read`);
      const updatedNotification = response.data;
      setNotifications(prevNotifications => 
        prevNotifications.map(notif => 
          notif.id === updatedNotification.id ? updatedNotification : notif
        )
      );
      return updatedNotification;
    } catch (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;

    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL || 'ws://localhost:8000'}/ws`);
    
    ws.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      setNotifications(prev => [...prev, newNotification]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [isLoggedIn]);

  const toggleNotifications = (e) => {
    e.stopPropagation();
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const unreadCount = notifications.filter(n => !n.read).length;

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'new_interest':
        return <FaHeart className="notification-type-icon new_interest" />;
      case 'new_question':
        return <FaQuestion className="notification-type-icon new_question" />;
      case 'question_answered':
        return <FaComment className="notification-type-icon question_answered" />;
      case 'new_review':
        return <FaStar className="notification-type-icon new_review" />;
      case 'review_updated':
        return <FaStar className="notification-type-icon review_updated" />;
      case 'review_liked':
        return <FaThumbsUp className="notification-type-icon review_liked" />;
      default:
        return null;
    }
  };

  const handleNotificationClick = useCallback(async (notification) => {
    try {
      await markAsRead(notification.id);
      navigate(notification.link);
    } catch (error) {
      console.error('Error handling notification click:', error);
    }
  }, [markAsRead, navigate]);

  const getDashboardLink = () => {
    if (user && user.role === 'admin') {
      return '/admin/dashboard';
    } else if (user && user.role === 'promoter') {
      return '/dashboard/events';
    }
    return '/dashboard'; // Default dashboard route
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar">
      <ul className="navbar-links">
        <li><Link to="/events">{t('events')}</Link></li>
        {isLoggedIn && <li><Link to={getDashboardLink()}>{t('dashboard')}</Link></li>}
      </ul>

      <div className="navbar-account">
        {isLoggedIn && (
          <div className="notification-icon" onClick={toggleNotifications}>
            <FaBell />
            {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
          </div>
        )}
        {isLoggedIn ? (
          <div className="dropdown">
            <button className="dropdown-btn initials-btn">
              {getInitials(getDisplayName())}
            </button>
            <div className="dropdown-content">
              <div className="user-info">
                <div className="user-initials">{getInitials(getDisplayName())}</div>
                <span className="user-name">{getDisplayName()}</span>
              </div>
              <div className="dropdown-divider"></div>
              <Link to={`/profile/${user.id}`}>{t('profile')}</Link>
              <button onClick={handleLogout}>{t('logout')}</button>
            </div>
          </div>
        ) : (
          <div className="auth-buttons">
            <Link to="/login" className="login-btn">{t('login')}</Link>
            <Link to="/register" className="register-btn">{t('register')}</Link>
          </div>
        )}
        <div className="language-switcher">
          <button onClick={() => changeLanguage('pt')}>PT</button>
          <button onClick={() => changeLanguage('en')}>EN</button>
        </div>
      </div>
      {showNotifications && (
        <div className="notifications-dropdown" ref={notificationRef}>
          <div className="notifications-header">
            <h3>{t('notifications')}</h3>
          </div>
          {notifications.length > 0 ? (
            <div className="notifications-list">
              {notifications.map(notification => (
                <div 
                  key={notification.id} 
                  className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="notification-indicators">
                    {!notification.read && <FaCircle className="unread-indicator" />}
                    {getNotificationIcon(notification.notification_type)}
                  </div>
                  <div className="notification-content">
                    <span className="notification-message">{notification.message}</span>
                    <span className="notification-time">{timeAgo(notification.created_at)}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-notifications">{t('noNotifications')}</div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
