import React from 'react';
import './EventCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faUser, faStar, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const EventCard = ({ event }) => {
  const { t, i18n } = useTranslation();

  // Function to format date according to current language
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat(i18n.language, {
      weekday: t('dateFormat.weekday'),
      month: t('dateFormat.month'),
      day: t('dateFormat.day'),
      year: t('dateFormat.year')
    }).format(date);
  };

  // Function to format price with currency
  const formatPrice = (price) => {
    if (price === 0) {
      return t('free');
    } else {
      return t('fromPrice', {
        price: new Intl.NumberFormat(i18n.language, {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(price)
      });
    }
  };

  return (
    <div className="event-card" onClick={() => window.location.href = `/event/${event.id}`}>
      <img src={event.image ? `${process.env.REACT_APP_API_URL}${event.image}` : process.env.REACT_APP_DEFAULT_IMAGE} alt={event.title} className="event-image" />
      <div className="event-details">
        <h3>{event.title}</h3>
        <p><FontAwesomeIcon icon={faCalendarAlt} /> {formatDate(event.date)}</p>
        <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {event.location}</p>
        <p>{formatPrice(event.price)}</p>
        {event.user && (
          <p>
            <FontAwesomeIcon icon={faUser} /> 
            {t('byPromoter', { name: event.user.account_name })}
          </p>
        )}
        <div className="event-stats">
          <div className="interest-counter">
            <FontAwesomeIcon icon={faHeart} />
            <span>{event.interest_count} {t('interested')}</span>
          </div>
          <div className="review-rating">
            <FontAwesomeIcon icon={faStar} />
            <span>{t('rating', { rating: event.average_rating })}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
