import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Heart, Share2 } from 'lucide-react';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart, faCalendarAlt, faClock, faTicketAlt, faStar, faTrash, faPencilAlt, faQuestionCircle, faCalendarPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import './EventDetailsPage.css';
import ErrorMessage from '../components/ErrorMessage';
import EventQA from '../components/EventQA';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import api from '../api';

const EventDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams(); // Get event ID from URL
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [reviews, setReviews] = useState([]); // State to store reviews
  const [newReview, setNewReview] = useState({ rating: 0, review_text: '' }); // State for new review input
  const [isInterested, setIsInterested] = useState(false); // Track interest state
  const [interestCount, setInterestCount] = useState(0); // Track the number of interested users
  const [showInterestedUsersModal, setShowInterestedUsersModal] = useState(false); // State to control interested users modal visibility
  const [interestedUsers, setInterestedUsers] = useState([]); // State to store interested users
  const [averageRating, setAverageRating] = useState(0); // State to store average rating
  const [ratingCounts, setRatingCounts] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }); // State to store rating counts
  const [userReview, setUserReview] = useState(null); // State to store the user's review
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if the user is authenticated
  const [showAllReviews, setShowAllReviews] = useState(false); // State to control "View More Reviews" modal visibility
  const [isEditing, setIsEditing] = useState(false); // State to track if the user is editing their review
  const [editingReview, setEditingReview] = useState(null); // State to store the review being edited
  const [error, setError] = useState(null); // Add state for error messages
  const [user, setUser] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const navigate = useNavigate();
  const [showQAModal, setShowQAModal] = useState(false);
  
  const getStatusStyle = (status) => {
    const baseStyle = {
      display: 'inline-block',
      padding: '6px 12px',
      borderRadius: '20px',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white',
    };
  
    const statusColors = {
      approved: 'rgba(76, 175, 80, 0.8)',  // Green with 80% opacity
      pending: 'rgba(255, 165, 0, 0.8)',   // Orange with 80% opacity
      rejected: 'rgba(244, 67, 54, 0.8)',  // Red with 80% opacity
      canceled: 'rgba(158, 158, 158, 0.8)', // Gray with 80% opacity
      postponed: 'rgba(33, 150, 243, 0.8)', // Blue with 80% opacity
      completed: 'rgba(103, 58, 183, 0.8)', // Purple with 80% opacity
      disabled: 'rgba(121, 85, 72, 0.8)',   // Brown with 80% opacity
    };
  
    return {
      ...baseStyle,
      backgroundColor: statusColors[status.toLowerCase()] || 'rgba(0, 0, 0, 0.8)',
    };
  };

  const fetchEventDetails = useCallback(async () => {
    try {
      const response = await api.get(`/event/${id}`);
      const data = response.data;
      
      if (data && data.event) {
        setEvent(data.event);
        setIsInterested(data.is_interested);
        setInterestCount(data.interest_count);
        setAverageRating(data.average_rating || 0);
        setRatingCounts(data.rating_counts || { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
        setReviews(data.reviews || []);
        
        const token = localStorage.getItem('access_token');
        setIsAuthenticated(!!token);
        
        if (token) {
          const userData = JSON.parse(localStorage.getItem('user_data'));
          setUser(userData);
          const userReview = data.reviews.find(review => review.user_id === userData.id);
          setUserReview(userReview);
        }
      } else {
        console.log("No event data found in the response.");
      }
    } catch (error) {
      console.error('Error fetching event:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchEventDetails();
  }, [fetchEventDetails]);

  useEffect(() => {
    if (event) {
      setShareUrl(`${window.location.origin}/event/${event.id}/preview`);
    }
  }, [event]);

  if (loading) {
    return <Loader />;
  }

  if (!event) {
    return <p>{t('eventDetails.loading')}</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long', month: 'short', day: 'numeric', year: 'numeric',
    }).format(date);
  };

  const formatPrice = (price) => {
    return price === 0 ? t('eventDetails.free') : t('eventDetails.price', { price: price.toFixed(2) });
  };

  // Function to open the modal
  const openModal = () => setShowModal(true);

  // Function to close the modal
  const closeModal = () => setShowModal(false);

  // Function to toggle the interest state
  const handleToggleInterest = async () => {
    if (!isAuthenticated) {
      alert("You need to log in to mark your interest in this event.");
      navigate('/login');
      return;
    }
    
    try {
      const response = await api.post(`/events/${id}/toggle-interest`);
      setIsInterested(!isInterested);
      setInterestCount(response.data.interest_count);
    } catch (error) {
      console.error('Error toggling interest:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Function to handle star click for new review
  const handleStarClick = (rating) => {
    setNewReview({ ...newReview, rating });
  };

  const sortedReviews = [...reviews].sort((a, b) => {
    if (a.user_id === user?.id) return -1;
    if (b.user_id === user?.id) return 1;
    return b.likes_count - a.likes_count || new Date(b.created_at) - new Date(a.created_at);
  });
  
  const submitOrEditReview = async () => {
    try {
      const url = isEditing 
        ? `/events/${id}/review/${editingReview.id}`
        : `/events/${id}/review`;
      const method = isEditing ? 'put' : 'post';
      
      const response = await api[method](url, {
        rating: newReview.rating,
        review_text: newReview.review_text
      });

      if (response.status !== 200) {
        throw new Error('Failed to submit review');
      }

      await fetchEventDetails();
      setIsEditing(false);
      setEditingReview(null);
      setNewReview({ rating: 0, review_text: '' });
    } catch (error) {
      console.error('Error submitting review:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Function to handle liking a review
  const handleLikeReview = async (reviewId) => {
    try {
      const response = await api.post(`/reviews/${reviewId}/like`);
      if (response.status !== 200) {
        throw new Error('Failed to like review');
      }
      
      const updatedReview = response.data;
      setReviews(prevReviews => prevReviews.map(r => 
        r.id === updatedReview.id ? {...r, ...updatedReview} : r
      ));
    } catch (error) {
      console.error('Error liking review:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Function to fetch interested users
  const fetchInterestedUsers = async () => {
    try {
      const response = await api.get(`/events/${id}/interested-users`);
      setInterestedUsers(response.data);
    } catch (error) {
      console.error('Error fetching interested users:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Function to open the interested users modal
  const openInterestedUsersModal = () => {
    fetchInterestedUsers();
    setShowInterestedUsersModal(true);
  };

  // Function to close the interested users modal
  const closeInterestedUsersModal = () => setShowInterestedUsersModal(false);

  const handleEditReview = (review) => {
    setIsEditing(true);
    setEditingReview(review);
    setNewReview({ rating: review.rating, review_text: review.review_text });
  };

  const renderRatingDistribution = () => {
    const maxCount = Math.max(...Object.values(ratingCounts));
    
    return (
      <div className="rating-summary">
        <div className="rating-distribution">
          {[5, 4, 3, 2, 1].map((star) => (
            <div key={star} className="rating-bar">
              <span>{star}</span>
              <div className="bar">
                <div
                  className="filled-bar"
                  style={{ width: maxCount > 0 ? `${(ratingCounts[star] / maxCount) * 100}%` : '0%' }}
                ></div>
              </div>
              <span>{ratingCounts[star] || 0}</span>
            </div>
          ))}
        </div>
        <div className="overall-rating">
          <h2>{averageRating.toFixed(1)}</h2>
          <div className="rating-stars">
            {[...Array(5)].map((_, i) => (
              <FontAwesomeIcon
                key={i}
                icon={faStar}
                className={i < Math.floor(averageRating) ? 'filled-star' : 'empty-star'}
              />
            ))}
          </div>
          <p>{reviews.length} reviews</p>
        </div>
      </div>
    );
  };

  const handleUserClick = (userId) => {
    navigate(`/user/${userId}`);
  };

  const handleDeleteReview = async (reviewId) => {
    if (!window.confirm(t('eventDetails.reviews.deleteConfirm'))) {
      return;
    }

    try {
      const response = await api.delete(`/events/${id}/review/${reviewId}`);
      if (response.status !== 200) {
        throw new Error('Failed to delete review');
      }
      await fetchEventDetails();
    } catch (error) {
      console.error('Error deleting review:', error);
      setError(error.message || 'An unexpected error occurred');
    }
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  const handleAddToCalendar = () => {
    window.open(`${process.env.REACT_APP_API_URL}/events/${id}/ical`, '_blank');
  };

  const generateGoogleCalendarLink = () => {
    if (!event) return '';
    const startDate = new Date(event.date).toISOString().replace(/-|:|\.\d\d\d/g,"");
    const endDate = new Date(new Date(event.date).getTime() + 2*60*60*1000).toISOString().replace(/-|:|\.\d\d\d/g,"");
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&dates=${startDate}/${endDate}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}`;
  };

  const generateOutlookCalendarLink = () => {
    if (!event) return '';
    const startDate = new Date(event.date).toISOString();
    const endDate = new Date(new Date(event.date).getTime() + 2*60*60*1000).toISOString();
    return `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(event.title)}&startdt=${startDate}&enddt=${endDate}&body=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}`;
  };

  const isEventInFuture = () => {
    if (!event?.date) return true;
    const eventDate = new Date(event.date);
    const today = new Date();
    return eventDate > today;
  };

  const formatReviewAvailableDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  return (
    <div className="event-details-page">
      <div className="event-header">
        {event.status !== "Approved" && (
          <div style={getStatusStyle(event.status)}>
            {event.status}
          </div>
        )}
        <div className="event-image-container">
          <img 
            src={event.image ? `${process.env.REACT_APP_API_URL}${event.image}` : process.env.REACT_APP_DEFAULT_IMAGE}
            alt={event.title} 
            className="event-image" 
            onClick={openModal}
            style={{ cursor: 'pointer' }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
            }}
          />
        </div>
        <p>{formatDate(event.date)} at {new Date(event.date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' })}</p>
        <h1>{event.title}</h1>
        <p>{event.location}</p>
        <p>{event.city}</p>
        <p>{formatPrice(event.price)}</p>
        <div className="event-actions">
          <button onClick={handleShare} className="share-button">
            <Share2 size={20} />
            {t('eventDetails.share')}
          </button>
          <button onClick={handleAddToCalendar} className="calendar-button">
            <FontAwesomeIcon icon={faCalendarAlt} />
            {t('eventDetails.addToCalendar')}
          </button>
          <a href={generateGoogleCalendarLink()} target="_blank" rel="noopener noreferrer" className="calendar-button google">
            <FontAwesomeIcon icon={faGoogle} />
            {t('eventDetails.googleCalendar')}
          </a>
          <a href={generateOutlookCalendarLink()} target="_blank" rel="noopener noreferrer" className="calendar-button outlook">
            <FontAwesomeIcon icon={faCalendarPlus} />
            {t('eventDetails.outlookCalendar')}
          </a>
        </div>
      </div>

      <div className="promoter-details">
        <h3>{t('eventDetails.dateAndTime')}</h3>
        <div className="event-date-time">
          <div className="event-date">
            <FontAwesomeIcon icon={faCalendarAlt} /> {/* Calendar icon */}
            <span>{formatDate(event.date)}</span>
          </div>
          <div className="event-time">
            <FontAwesomeIcon icon={faClock} /> {/* Clock icon */}
            <span>{new Date(event.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
          </div>
        </div>
      </div>      

      <div className="event-overview">
        <div className="event-details-column">
          <p><strong>{event.subtitle}</strong></p>
          <p>{event.description}</p>
          {event.venue_capability && (
            <p><strong>{t('eventDetails.venueCapacity')}:</strong> {t('eventDetails.capacity', { count: event.venue_capability })}</p>
          )}
          {event.duration && (
            <p><strong>{t('eventDetails.eventDuration')}:</strong> {t('eventDetails.duration', { hours: event.duration })}</p>
          )}
        </div>

        <div className="event-buttons-column">
          <div className="call-to-action">
            {event.ticket && (
              <a href={event.ticket} className="btn-buy-tickets">
                <FontAwesomeIcon icon={faTicketAlt} />
                {t('eventDetails.buyTickets')}
              </a>
            )}
            <motion.div
              className="relative"
              whileTap={{ scale: 0.95 }}
            >
              <button
                onClick={handleToggleInterest}
                className={`interest-button ${
                  isInterested ? 'interest-button-interested' : 'interest-button-not-interested'
                }`}
              >
                <Heart size={20} />
                {isInterested ? t('eventDetails.interested') : t('eventDetails.showInterest')}
              </button>
            </motion.div>
            <div className="interest-count">
              {t('eventDetails.interestedCount', { count: interestCount })}
            </div>
            <button 
                onClick={openInterestedUsersModal} 
                className="view-interested-users"
              >
                {t('eventDetails.viewInterestedUsers')}
              </button>
          </div>
        </div>
      </div>

      <div className="promoter-details">
        <h3>{t('eventDetails.organizedBy')}</h3>
        <Link to={`/profile/${event.user.id}`} className="promoter-info">
          {event.user?.logo ? (
            <img 
              src={`${process.env.REACT_APP_API_URL}${event.user.logo}`}
              alt={`${event.user.first_name}'s logo`}
              className="organizer-logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
              }}
            />
          ) : (
            <div className="promoter-initials">
              {event.user?.first_name?.[0] || ''}
              {event.user?.last_name?.[0] || ''}
            </div>
          )}
          <div className="promoter-name">
            {event.user?.first_name || t('eventDetails.unknown')} {event.user?.last_name || t('eventDetails.user')}
            {event.user?.verified && (
              <span className="verified-badge" title={t('eventDetails.verifiedUser')}>
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
            )}
          </div>
        </Link>
      </div>

      <div className="map-section">
        <h3>{t('eventDetails.location')}</h3>
        <iframe
          title="Google Maps"
          src={`https://www.google.com/maps?q=${event.latitude},${event.longitude}&hl=es;z=14&output=embed`}
          className="event-map"
          allowFullScreen
        ></iframe>
      </div>
      
      <Modal show={showModal} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{event.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img 
            src={`${process.env.REACT_APP_API_URL}${event.image}`}
            alt={event.title} 
            style={{ width: '100%' }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
            }}
          />
        </Modal.Body>
      </Modal>

             {/* Interested Users Modal */}
      <Modal show={showInterestedUsersModal} onHide={closeInterestedUsersModal} size="lg" centered>
        <Modal.Header closeButton>
           <Modal.Title>{t('eventDetails.interestedUsers.title')}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
           {interestedUsers.length > 0 ? (
             <div className="interested-users-list">
               {interestedUsers.map((user, index) => (
                 <button 
                 key={user.id} 
                 className="interested-user"
                 onClick={(e) => {
                   e.preventDefault();
                   closeInterestedUsersModal();
                   handleUserClick(user.id);
                   }}
                 >
                   <div className="user-number">{index + 1}.</div>
                   <div className="user-initials">
                     {user.first_name[0]}{user.last_name[0]}
                   </div>
                   <div className="user-name">
                     {user.first_name} {user.last_name}
                   </div>
                 </button>
               ))}
             </div>
           ) : (
             <p>{t('eventDetails.interestedUsers.noUsers')}</p>
           )}
         </Modal.Body>
       </Modal>

     
      {/* Reviews Section */}
      {isEventInFuture() ? (
        <div className="reviews-pending-section">
          <FontAwesomeIcon icon={faClock} className="clock-icon" />
          <h3>{t('eventDetails.reviews.comingSoon')}</h3>
          <p>
            {t('eventDetails.reviews.availableFrom', {
              date: formatReviewAvailableDate(event.date)
            })}
          </p>
          {!isInterested && (
            <button 
              onClick={handleToggleInterest}
              className="notify-button"
            >
              {t('eventDetails.reviews.getNotified')}
            </button>
          )}
        </div>
      ) : (
        <div className="reviews-section">
          <h3>{t('eventDetails.reviews.title')}</h3>
          {renderRatingDistribution()}
          {sortedReviews.length > 0 ? sortedReviews.slice(0, 3).map((review) => (
            <div key={review.id} className="review">
              <div className="review-header">
                <button 
                  className="reviewer-info"
                  onClick={() => handleUserClick(review.user.id)}
                >
                  <div className="reviewer-initials">
                    {review.user?.first_name?.[0] || ''}
                    {review.user?.last_name?.[0] || ''}
                  </div>
                  <div className="reviewer-name">
                    {review.user?.first_name || t('eventDetails.reviews.unknown')} {review.user?.last_name || t('eventDetails.reviews.user')}
                  </div>
                </button>
              </div>
              <div className="review-rating">
                {[...Array(5)].map((_, i) => (
                  <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={i < review.rating ? 'filled-star' : 'empty-star'}
                  />
                ))}
              </div>
              <p className="review-text">
                {review.review_text} {review.edited && <span className="edited-label">{t('eventDetails.reviews.edited')}</span>}
              </p>
              <div className="review-actions">
                <button 
                  onClick={() => handleLikeReview(review.id)} 
                  className={`like-button ${review.user_has_liked ? 'liked' : ''}`}
                >
                  <FontAwesomeIcon 
                    icon={review.user_has_liked ? fasHeart : farHeart} 
                    style={{ color: review.user_has_liked ? 'red' : 'gray' }}
                  /> {review.likes_count}
                </button>
                {user && review.user_id === user.id && (
                  <>
                    <button 
                      onClick={() => handleEditReview(review)} 
                      className="icon-button edit-button" 
                      title={t('eventDetails.reviews.editButton')}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button 
                      onClick={() => handleDeleteReview(review.id)} 
                      className="icon-button delete-button" 
                      title={t('eventDetails.reviews.deleteButton')}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                )}
              </div>
            </div>
          )) : <p>{t('eventDetails.reviews.noReviews')} {t('eventDetails.reviews.beFirst')}</p>}

          {/* Submit Review Section */}
          {isAuthenticated ? (
            (!userReview || isEditing) && (
              <div className="review-form">
                <h4>{isEditing ? t('eventDetails.reviews.editReview') : t('eventDetails.reviews.leaveReview')}</h4>
                <div className="star-selection">
                  {[...Array(5)].map((_, i) => (
                    <FontAwesomeIcon
                      key={i}
                      icon={faStar}
                      className={i < newReview.rating ? 'filled-star' : 'empty-star'}
                      onClick={() => handleStarClick(i + 1)}
                    />
                  ))}
                </div>
                <textarea 
                  value={newReview.review_text} 
                  onChange={(e) => setNewReview({ ...newReview, review_text: e.target.value })}
                  placeholder={t('eventDetails.reviews.writeReview')}
                />
                <button onClick={submitOrEditReview}>
                  {isEditing ? t('eventDetails.reviews.updateReview') : t('eventDetails.reviews.submitReview')}
                </button>
                {isEditing && (
                  <button onClick={() => {
                    setIsEditing(false);
                    setEditingReview(null);
                    setNewReview({ rating: 0, review_text: '' });
                  }}>{t('eventDetails.reviews.cancel')}</button>
                )}
              </div>
            )
          ) : (
            <p>
              {t('eventDetails.reviews.loginToReview')} 
              <button onClick={() => navigate('/login')}>
                {t('eventDetails.reviews.loginButton')}
              </button>
            </p>
          )}

          {sortedReviews.length > 3 && (
            <button 
              onClick={() => setShowAllReviews(true)} 
              className="view-more-reviews"
            >
              {t('eventDetails.reviews.viewMore')}
            </button>
          )}
        </div>
      )}

      {/* All Reviews Modal */}
      <Modal show={showAllReviews} onHide={() => setShowAllReviews(false)} size="lg" centered>
  <Modal.Header closeButton>
    <Modal.Title>{t('eventDetails.reviews.allReviews')}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {sortedReviews.map((review) => (
      <div key={review.id} className="review">
        <div className="review-header">
          <button 
            className="reviewer-info"
            onClick={() => {
              if (review.user && review.user.id) {
                handleUserClick(review.user.id);
                setShowAllReviews(false);
              } else {
                console.error('User ID is missing from the review');
                setError('Unable to fetch user profile: User information is missing');
              }
            }}
          >
            <div className="reviewer-initials">
              {review.user?.first_name?.[0] || ''}
              {review.user?.last_name?.[0] || ''}
            </div>
            <div className="reviewer-name">
              {review.user?.first_name || 'Unknown'} {review.user?.last_name || 'User'}
            </div>
          </button>
        </div>
        <div className="review-rating">
          {[...Array(5)].map((_, i) => (
            <FontAwesomeIcon
              key={i}
              icon={faStar}
              className={i < review.rating ? 'filled-star' : 'empty-star'}
            />
          ))}
        </div>
        <p className="review-text">{review.review_text} {review.edited && <span className="edited-label">(Edited)</span>}</p>
        <div className="review-actions">
          <button 
            onClick={() => handleLikeReview(review.id)} 
            className={`like-button ${review.user_has_liked ? 'liked' : ''}`}
          >
            <FontAwesomeIcon 
              icon={review.user_has_liked ? fasHeart : farHeart} 
              style={{ color: review.user_has_liked ? 'red' : 'gray' }}
            /> {review.likes_count}
          </button>
          {user && review.user_id === user.id && (
            <>
              <button onClick={() => handleEditReview(review)} className="icon-button edit-button" title="Edit review">
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              <button onClick={() => handleDeleteReview(review.id)} className="icon-button delete-button" title="Delete review">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </>
          )}
        </div>
      </div>
    ))}
  </Modal.Body>
</Modal>

      {/* Share Modal */}
      <Modal show={showShareModal} onHide={() => setShowShareModal(false)} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('eventDetails.share1.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-options">
            <FacebookShareButton url={shareUrl} quote={event.title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={event.title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl} title={event.title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="share-link">
            <input type="text" value={shareUrl} readOnly />
            <button onClick={copyToClipboard}>{t('eventDetails.share1.copy')}</button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Error Message */}
      {error && (
        <ErrorMessage 
          message={error} 
          type="error" 
          onClose={() => setError(null)} 
        />
      )}

      {/* Q&A Button */}
      <button 
        className="qa-button fixed-corner"
        onClick={() => setShowQAModal(true)}
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
        {t('eventDetails.qa.askQuestion')}
      </button>

      {/* Q&A Modal */}
      <Modal show={showQAModal} onHide={() => setShowQAModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('eventDetails.qa.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventQA eventId={id} eventUserId={event?.user?.id} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EventDetailsPage;