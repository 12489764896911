import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';
import logo from '../components/logos/soeventos_logo_icon.png';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-container">
      <div className="about-header">
        <img src={logo} alt="Soeventos Logo" className="about-logo" />
        <h1>{t('about.title')}</h1>
      </div>

      <section className="about-section mission">
        <h2>{t('about.mission.title')}</h2>
        <p>{t('about.mission.content')}</p>
      </section>

      <section className="about-section vision">
        <div className="stat-grid">
          <div className="stat-item">
            <span className="stat-number">10K+</span>
            <span className="stat-label">{t('about.stats.events')}</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">50K+</span>
            <span className="stat-label">{t('about.stats.users')}</span>
          </div>
          <div className="stat-item">
            <span className="stat-number">100+</span>
            <span className="stat-label">{t('about.stats.promoters')}</span>
          </div>
        </div>
      </section>

      <section className="about-section values">
        <h2>{t('about.values.title')}</h2>
        <div className="values-grid">
          {['trust', 'innovation', 'community'].map((value) => (
            <div key={value} className="value-item">
              <h3>{t(`about.values.${value}.title`)}</h3>
              <p>{t(`about.values.${value}.content`)}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="about-section contact">
        <h2>{t('about.contact.title')}</h2>
        <p>{t('about.contact.content')}</p>
        <div className="contact-info">
          <a href="mailto:contact@soeventos.co.mz" className="contact-link">
            contact@soeventos.co.mz
          </a>
        </div>
      </section>
    </div>
  );
};

export default About; 