import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './Modal.css';

const ReviewsModal = ({ reviews, onClose }) => {
  const { t } = useTranslation();

  const renderStarRating = (rating) => {
    return (
      <div className="review-rating">
        {[...Array(5)].map((_, i) => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            className={i < rating ? 'filled-star' : 'empty-star'}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{t('reviewsModal.title')}</h2>
        <ul className="modal-list">
          {reviews.map(review => (
            <li key={review.id}>
              <Link to={`/event/${review.event_id}`}>
                <h3>{review.event_title}</h3>
                {renderStarRating(review.rating)}
                <p>{review.review_text}</p>
                <p className="review-date">
                  {t('reviewsModal.reviewedOn', {
                    date: new Date(review.created_at).toLocaleDateString()
                  })}
                </p>
              </Link>
            </li>
          ))}
        </ul>
        <button onClick={onClose}>{t('reviewsModal.close')}</button>
      </div>
    </div>
  );
};

export default ReviewsModal;