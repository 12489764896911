import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Modal.css';

const EventsModal = ({ events, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{t('eventsModal.title')}</h2>
        <ul className="modal-list">
          {events.map(event => (
            <li key={event.id}>
              <Link to={`/event/${event.id}`}>
                <div className="event-item">
                  <img src={event.image} alt={event.title} className="event-thumbnail" />
                  <div className="event-details">
                    <h3>{event.title}</h3>
                    <p>
                      <span>{t('eventsModal.details.date')}: </span>
                      {new Date(event.date).toLocaleDateString()}
                    </p>
                    <p>
                      <span>{t('eventsModal.details.location')}: </span>
                      {event.location}
                    </p>
                    <p>{event.description.substring(0, 100)}... {t('eventsModal.details.readMore')}</p>
                    <p>
                      <span>{t('eventsModal.details.category')}: </span>
                      {t(`categories.${event.category.toLowerCase()}`)}
                    </p>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <button onClick={onClose}>{t('eventsModal.close')}</button>
      </div>
    </div>
  );
};

export default EventsModal;