import React from 'react';
import { useTranslation } from 'react-i18next';
import './Terms.css';

const Terms = () => {
  const { t } = useTranslation();

  const renderSubsection = (subsection, key) => {
    if (!subsection) return null;
    
    return (
      <div key={key} className="terms-subsection">
        {subsection.title && <h3>{subsection.title}</h3>}
        {subsection.content && (
          <div className="terms-content">
            {subsection.content.split('\n').map((paragraph, idx) => {
              if (!paragraph) return null;
              if (paragraph.startsWith('•')) {
                return <li key={idx}>{paragraph.substring(1).trim()}</li>;
              }
              if (paragraph === '') {
                return <br key={idx} />;
              }
              return <p key={idx}>{paragraph}</p>;
            })}
          </div>
        )}
        {subsection.items && (
          <ul className="terms-list">
            {Object.entries(subsection.items).map(([itemKey, item]) => (
              <li key={itemKey}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderSection = (section, sectionKey) => {
    if (!section) return null;

    return (
      <section key={sectionKey} className="terms-section">
        {section.title && <h2>{section.title}</h2>}
        {section.content && (
          <div className="terms-content">
            {section.content.split('\n').map((paragraph, idx) => (
              <p key={idx}>{paragraph}</p>
            ))}
          </div>
        )}
        {section.subsections && (
          <div className="terms-subsections">
            {Object.entries(section.subsections).map(([key, subsection]) => 
              renderSubsection(subsection, key)
            )}
          </div>
        )}
        {section.items && (
          <div className="terms-items">
            {Object.entries(section.items).map(([key, item]) => 
              renderSubsection(item, key)
            )}
          </div>
        )}
      </section>
    );
  };

  return (
    <div className="terms-container">
      <h1>{t('terms.title')}</h1>
      <p className="last-updated">
        {t('terms.lastUpdated', { date: '20 de Março de 2024' })}
      </p>

      {Object.entries(t('terms.sections', { returnObjects: true }) || {}).map(
        ([key, section]) => renderSection(section, key)
      )}
    </div>
  );
};

export default Terms; 