import React from 'react';
import './Loader.css';

const Loader = ({ size = 'medium', center = true }) => {
  const sizeClass = {
    small: 'loader-sm',
    medium: 'loader-md',
    large: 'loader-lg'
  }[size];

  return (
    <div className={`loader-container ${center ? 'loader-centered' : ''}`}>
      <div className={`loader ${sizeClass}`}>
        <div className="loader-spinner"></div>
      </div>
    </div>
  );
};

export default Loader; 