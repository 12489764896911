import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>{t('footer.company')}</h4>
          <a href="/about">{t('footer.links.about')}</a>
        </div>

        <div className="footer-section">
          <h4>{t('footer.legal')}</h4>
          <a href="/privacy">{t('footer.links.privacy')}</a>
          <a href="/terms">{t('footer.links.terms')}</a>
        </div>

        <div className="footer-section">
          <h4>{t('footer.support')}</h4>
          <a href="/help">{t('footer.links.help')}</a>
          <a href="/faq">{t('footer.links.faq')}</a>
        </div>

        <div className="footer-section social">
          <h4>{t('footer.followUs')}</h4>
          <div className="social-links">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
      
      <div className="footer-bottom">
      <p>{t('footer.copyright', { year: new Date().getFullYear() })}</p>
      </div>
    </footer>
  );
};

export default Footer;
