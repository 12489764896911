import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Register.css';
import ErrorMessage from '../components/ErrorMessage';
import logo from '../components/logos/soeventos_logo_icon.png';
import api from '../api';

const Register = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isPromoter, setIsPromoter] = useState(false);
  const [promoterName, setPromoterName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [sex, setSex] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const validatePasswords = () => {
    if (password !== confirmPassword) {
      setPasswordError(t('passwordReset.passwordsDoNotMatch'));
      return false;
    }
    setPasswordError('');
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validatePasswords()) {
      return;
    }

    try {
      const response = await api.post('/auth/register', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        role: isPromoter ? 'promoter' : 'user',
        account_name: isPromoter ? promoterName : null,
        date_of_birth: dateOfBirth,
        sex: sex,
      });

      if (response.status === 200) {
        setRegistrationComplete(true);
      }
    } catch (error) {
      console.error('Error registering:', error);
      const errorMessage = error.response?.data?.detail;
      setError(t(`errors.${errorMessage}`) || errorMessage || t('errorOccurred'));
    }
  };

  if (registrationComplete) {
    return (
      <div className="auth-page">
        <div className="auth-container verification-message">
          <div className="logo-container">
            <img 
              src={logo} 
              alt="EventHub" 
              className="auth-logo"
            />
          </div>
          <h2>{t('emailVerification.title')}</h2>
          <p>{t('emailVerification.message')}</p>
          <p className="spam-notice">{t('emailVerification.checkSpam')}</p>
          <button 
            className="auth-button"
            onClick={() => navigate('/login')}
          >
            {t('login')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="logo-container">
          <img 
            src={logo} 
            alt="EventHub" 
            className="auth-logo"
          />
        </div>

        <div className="auth-header">
          <h1>{t('createAccount')}</h1>
          <p>{t('joinCommunity')}</p>
        </div>

        {error && (
          <ErrorMessage 
            message={error} 
            type="error" 
            onClose={() => setError(null)} 
          />
        )}

        <form onSubmit={handleRegister} className="auth-form">
          <div className="form-group checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isPromoter}
                onChange={(e) => setIsPromoter(e.target.checked)}
                className="checkbox-input"
              />
              <span>{t('registerAsPromoter')}</span>
            </label>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t('firstName')}
                required
                className="auth-input"
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t('lastName')}
                required
                className="auth-input"
              />
            </div>
          </div>

          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              required
              className="auth-input"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError('');
              }}
              placeholder={t('password')}
              required
              className="auth-input"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordError('');
              }}
              placeholder={t('passwordReset.confirmPassword')}
              required
              className={`auth-input ${passwordError ? 'error' : ''}`}
            />
            {passwordError && (
              <div className="password-error-message">
                {passwordError}
              </div>
            )}
          </div>

          <div className="form-group">
            <label className="auth-label">{t('dob')}</label>
            <input
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              required
              className="auth-input"
            />
          </div>

          <div className="form-group">
            <select
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              required
              className="auth-input"
            >
              <option value="">{t('selectGender')}</option>
              <option value="male">{t('male')}</option>
              <option value="female">{t('female')}</option>
              <option value="other">{t('other')}</option>
              <option value="prefer_not_to_say">{t('preferNotToSay')}</option>
            </select>
          </div>

          {isPromoter && (
            <div className="form-group">
              <input
                type="text"
                value={promoterName}
                onChange={(e) => setPromoterName(e.target.value)}
                placeholder={t('promoterName')}
                required
                className="auth-input"
              />
            </div>
          )}

          <div className="form-group checkbox-group terms-checkbox">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                className="checkbox-input"
                required
              />
              <span>
                {t('acceptTerms')}{' '}
                <a 
                  href="/terms" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="terms-link"
                >
                  {t('termsLink')}
                </a>
              </span>
            </label>
          </div>

          <button 
            type="submit" 
            className={`auth-button ${!acceptedTerms ? 'disabled' : ''}`}
            disabled={!acceptedTerms}
          >
            {t('createAccount')}
          </button>
        </form>

        <div className="auth-footer">
          <p>
            {t('alreadyHaveAccount')} {' '}
            <Link to="/login" className="auth-link">
              {t('signIn')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
