import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Loader from '../components/Loader';

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const { isLoggedIn, user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (user.is_active) {
    return (
      <div className="alert alert-danger m-3" role="alert">
        Your account is inactive. Please contact the site administrator.
      </div>
    );
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  return typeof children === 'function' ? children({ user }) : children;
};

export default ProtectedRoute;
