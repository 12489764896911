import React, { useEffect, useState, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Alert, Form, Dropdown, Modal } from 'react-bootstrap';
import { Eye, MoreVertical } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import api from '../api';
import './PromoterDashboard.css';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const AdminDashboard = () => {
  const [events, setEvents] = useState([]);
  const [overallAnalytics, setOverallAnalytics] = useState(null);
  const [activeTab, setActiveTab] = useState('events');
  const [successMessage, setSuccessMessage] = useState('');
  const [timeRange, setTimeRange] = useState('all');
  const [statusFilter, setStatusFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchAllEvents = useCallback(async () => {
    try {
      const response = await api.get(`/admin/events${statusFilter ? `?status=${statusFilter}` : ''}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching all events:', error);
    }
  }, [statusFilter]);

  const fetchOverallAnalytics = useCallback(async () => {
    try {
      const response = await api.get(`/admin/dashboard/overall-analytics?time_range=${timeRange}`);
      setOverallAnalytics(response.data);
    } catch (error) {
      console.error('Error fetching overall analytics:', error);
    }
  }, [timeRange]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await api.get('/users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);

  useEffect(() => {
    fetchAllEvents();
    fetchOverallAnalytics();
  }, [fetchAllEvents, fetchOverallAnalytics]);

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    }
  }, [activeTab, fetchUsers]);

  const handleApproveEvent = async (eventId) => {
    try {
      await api.put(`/admin/events/${eventId}/approve`);
      setSuccessMessage(t('adminDashboard.messages.eventApproved'));
      fetchAllEvents();
    } catch (error) {
      console.error(t('adminDashboard.errors.approveEvent'), error);
    }
  };

  const handleRejectEvent = async (eventId) => {
    try {
      await api.put(`/admin/events/${eventId}/reject`);
      setSuccessMessage('Event rejected successfully');
      fetchAllEvents();
    } catch (error) {
      console.error('Error rejecting event:', error);
    }
  };

  const handleViewEvent = async (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleViewUser = (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
  };

  const handleVerifyUser = async (userId) => {
    try {
      await api.put(`/users/${userId}/verify`);
      setSuccessMessage('User verified successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error verifying user:', error);
    }
  };

  const handleDisableUser = async (userId) => {
    try {
      await api.put(`/users/${userId}/disable`);
      setSuccessMessage('User disabled successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error disabling user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      setSuccessMessage('User deleted successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderUserModal = () => (
    <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedUser && (
          <div>
            <p>Name: {selectedUser.first_name} {selectedUser.last_name}</p>
            <p>Email: {selectedUser.email}</p>
            <p>Role: {selectedUser.role}</p>
            <p>Verified: {selectedUser.verified ? 'Yes' : 'No'}</p>
            <p>Active: {selectedUser.is_active ? 'Yes' : 'No'}</p>
            {/* Add more fields as needed */}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );

  const renderOverallAnalytics = () => {
    if (!overallAnalytics) return <p>{t('adminDashboard.analytics.loading')}</p>;

    return (
      <div className="overall-analytics">
        <div className="time-range-selector">
          <select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="all">{t('adminDashboard.analytics.timeRange.all')}</option>
            <option value="week">{t('adminDashboard.analytics.timeRange.week')}</option>
            <option value="month">{t('adminDashboard.analytics.timeRange.month')}</option>
            <option value="year">{t('adminDashboard.analytics.timeRange.year')}</option>
          </select>
        </div>
        <div className="analytics-data">
          <div className="data-item">
            <p className="data-label">{t('adminDashboard.analytics.stats.totalEvents')}</p>
            <p className="data-value">{overallAnalytics.total_events}</p>
          </div>
          <div className="data-item">
            <p className="data-label">{t('adminDashboard.analytics.stats.totalPromoters')}</p>
            <p className="data-value">{overallAnalytics.total_promoters}</p>
          </div>
          <div className="data-item">
            <p className="data-label">{t('adminDashboard.analytics.stats.totalUsers')}</p>
            <p className="data-value">{overallAnalytics.total_users}</p>
          </div>
          <div className="data-item">
            <p className="data-label">{t('adminDashboard.analytics.stats.totalInterest')}</p>
            <p className="data-value">{overallAnalytics.total_interest}</p>
          </div>
          <div className="data-item">
            <p className="data-label">{t('adminDashboard.analytics.stats.averageRating')}</p>
            <p className="data-value">{overallAnalytics.average_rating.toFixed(2)}</p>
          </div>
        </div>
        <div className="demographics">
          <h3>{t('adminDashboard.analytics.demographics.title')}</h3>
          <div className="demographics-details">
            {Object.entries(overallAnalytics.demographics).map(([sex, data]) => (
              <p key={sex}>{sex}: {data.count} users (Avg. Age: {data.avg_age.toFixed(1)})</p>
            ))}
          </div>
        </div>
        <div className="age-distribution">
          <h3>{t('adminDashboard.analytics.demographics.ageDistribution')}</h3>
          <Bar
            data={{
              labels: Object.keys(overallAnalytics.age_distribution),
              datasets: [{
                label: t('adminDashboard.analytics.demographics.chartLabels.users'),
                data: Object.values(overallAnalytics.age_distribution),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
              }]
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: t('adminDashboard.analytics.demographics.chartLabels.users')
                  }
                },
                x: {
                  title: {
                    display: true,
                    text: t('adminDashboard.analytics.demographics.chartLabels.ageGroup')
                  }
                }
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderEventManagement = () => {
    return (
      <div className="event-management">
        <Form.Select 
          value={statusFilter} 
          onChange={(e) => setStatusFilter(e.target.value)}
          className="mb-3"
        >
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          <option value="Postponed">Postponed</option>
          <option value="Canceled">Canceled</option>
          <option value="Completed">Completed</option>
        </Form.Select>
        <table className="event-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Location</th>
              <th>Promoter</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => {
              const eventData = event.event;
              return (
              <tr key={eventData.id}>
                <td>{eventData.title}</td>
                <td>
                  <FontAwesomeIcon icon={faCalendarAlt} /> {new Date(eventData.date).toLocaleDateString()}
                </td>
                <td>
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> {eventData.location}
                </td>
                <td>{eventData.user.account_name}</td>
                <td>{eventData.status}</td>
                <td>
                  <button className="btn-view" onClick={() => handleViewEvent(eventData.id)}>
                    <Eye size={16} />
                  </button>
                  {eventData.status === 'Pending' && (
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id={`dropdown-${eventData.id}`}>
                        <MoreVertical size={16} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleApproveEvent(eventData.id)}>Approve</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleRejectEvent(eventData.id)}>Reject</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
              </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTabs = () => (
    <div className="dashboard-tabs">
      <button 
        className={`tab-button ${activeTab === 'events' ? 'active' : ''}`}
        onClick={() => setActiveTab('events')}
      >
        {t('adminDashboard.tabs.events')}
      </button>
      <button 
        className={`tab-button ${activeTab === 'overall' ? 'active' : ''}`}
        onClick={() => setActiveTab('overall')}
      >
        Overall Analytics
      </button>
      <button 
        className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
        onClick={() => setActiveTab('users')}
      >
        User Management
      </button>
    </div>
  );

  const renderContent = () => {
    if (activeTab === 'overall') {
      return renderOverallAnalytics();
    } else if (activeTab === 'events') {
      return renderEventManagement();
    } else if (activeTab === 'users') {
      return renderUserManagement();
    }
  };

  const renderUserManagement = () => {
    return (
      <div className="event-management">
        <table className="event-table">
          <thead>
            <tr>
              <th>{t('adminDashboard.users.table.name')}</th>
              <th>{t('adminDashboard.users.table.email')}</th>
              <th>{t('adminDashboard.users.table.role')}</th>
              <th>{t('adminDashboard.users.table.verified')}</th>
              <th>{t('adminDashboard.users.table.active')}</th>
              <th>{t('adminDashboard.users.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>{user.verified ? t('adminDashboard.users.status.yes') : t('adminDashboard.users.status.no')}</td>
                <td>{user.is_active ? t('adminDashboard.users.status.yes') : t('adminDashboard.users.status.no')}</td>
                <td>
                  <button onClick={() => handleViewUser(user)}>{t('adminDashboard.users.actions.view')}</button>
                  <button onClick={() => handleVerifyUser(user.id)}>{t('adminDashboard.users.actions.verify')}</button>
                  <button onClick={() => handleDisableUser(user.id)}>{t('adminDashboard.users.actions.disable')}</button>
                  <button onClick={() => handleDeleteUser(user.id)}>{t('adminDashboard.users.actions.delete')}</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {renderUserModal()}
      </div>
    );
  };

  return (
    <div className="promoter-dashboard">
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
          {successMessage}
        </Alert>
      )}
      <div className="dashboard-content">
        <div className="analytics-container">
          {renderTabs()}
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
