import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../AuthContext';
import api from '../api';
import './Login.css';
import logo from '../components/logos/soeventos_logo_icon.png';

// Constants for routes
const ROUTES = {
  PROMOTER_DASHBOARD: '/dashboard/events',
  ADMIN_DASHBOARD: '/admin/dashboard',
  EVENTS: '/events',
  LOGIN: '/login'
};

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationStatus, setVerificationStatus] = useState({
    success: false,
    error: false
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const verified = params.get('verified');
    
    if (verified === 'true') {
      setVerificationStatus({ success: true, error: false });
      window.history.replaceState({}, '', ROUTES.LOGIN);
    } else if (verified === 'false') {
      setVerificationStatus({ success: false, error: true });
      window.history.replaceState({}, '', ROUTES.LOGIN);
    }
  }, []);

  useEffect(() => {
    // Debug logging
    console.log('Login component mounted');
    console.log('API URL:', process.env.REACT_APP_API_URL);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Login attempt with:', { email });
    console.log('Making request to:', `${process.env.REACT_APP_API_URL}/auth/login`);
    
    try {
      const formData = new URLSearchParams();
      formData.append('username', email);
      formData.append('password', password);

      console.log('Request payload:', formData.toString());
      
      const response = await api.post('/auth/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log('Login response:', response.data);
      
      if (response.status === 200) {
        const data = response.data;
        try {
          await login(data.access_token, {
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            account_name: data.account_name,
            logo: data.logo,
            role: data.role,
            email: data.email,
            is_active: data.is_active
          });
          
          // Navigate based on user role
          switch (data.role) {
            case 'promoter':
              navigate(ROUTES.PROMOTER_DASHBOARD);
              break;
            case 'admin':
              navigate(ROUTES.ADMIN_DASHBOARD);
              break;
            default:
              navigate(ROUTES.EVENTS);
          }
        } catch (loginError) {
          setErrorMessage(loginError.message);
        }
      }
    } catch (error) {
      console.log('Full error object:', error);
      console.log('Error config:', error.config);
      console.log('Error response:', error.response);
      setErrorMessage(error.response?.data?.detail || t('invalidCredentials'));
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="logo-container">
          <img 
            src={logo} 
            alt="EventHub" 
            className="auth-logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = process.env.REACT_APP_DEFAULT_IMAGE;
            }}
          />
        </div>

        {verificationStatus.success && (
          <div className="success-alert">
            <p>{t('emailVerification.success')}</p>
          </div>
        )}
        {verificationStatus.error && (
          <div className="error-alert">
            <p>{t('emailVerification.error')}</p>
          </div>
        )}

        <div className="auth-header">
          <h1>{t('welcomeBack')}</h1>
          <p>{t('signInToContinue')}</p>
        </div>

        {errorMessage && (
          <div className="error-alert">
            <p>{errorMessage}</p>
          </div>
        )}

        <form onSubmit={handleLogin} className="auth-form" autoComplete="on">
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('email')}
              required
              className="auth-input"
              autoComplete="email"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('password')}
              required
              className="auth-input"
              autoComplete="current-password"
            />
          </div>

          <button type="submit" className="auth-button">
            {t('signIn')}
          </button>
        </form>

        <div className="auth-footer">
          <p>
            {t('dontHaveAccount')} {' '}
            <Link to="/register" className="auth-link">
              {t('signUp')}
            </Link>
          </p>
          <Link to="/forgot-password" className="auth-link forgot-password">
            {t('forgotPassword')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
