import React from 'react';
// import { useLocation } from 'react-router-dom';
import './BrandHeader.css'; // Add your styles here
import soeventosLogo from './logos/soeventos_logo_v1.png';

const BrandHeader = () => {
  //const location = useLocation();

  // Hide the header if on the homepage
  //if (location.pathname === '/') {
  //  return null;
  //}

  return (
    <div className="App-header">
      <img src={soeventosLogo} alt="SoEventos Logo" className="brand-logo" />
    </div>
  );
};

export default BrandHeader;
