import React from 'react';
import './ErrorMessage.css';

const ErrorMessage = ({ message, type = 'error', onClose }) => {
  if (!message) return null;

  return (
    <div className={`message-container ${type}`}>
      <div className="message-content">
        <span className="message-text">{message}</span>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default ErrorMessage;
