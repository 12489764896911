import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../api';
import './ResetPassword.css';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage(t('passwordReset.passwordsDoNotMatch'));
      setIsSuccess(false);
      return;
    }

    try {
      const response = await api.post('/auth/reset-password', {
        token,
        new_password: password,
      });

      setMessage(response.data.message);
      setIsSuccess(true);
      setPassword('');
      setConfirmPassword('');
      
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setMessage(t('errorOccurred'));
      setIsSuccess(false);
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <div className="auth-header">
          <h1>{t('passwordReset.title')}</h1>
          <p>{t('passwordReset.enterNewPassword')}</p>
        </div>

        {message && (
          <div className={`alert ${isSuccess ? 'success-alert' : 'error-alert'}`}>
            <p>{message}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('passwordReset.newPassword')}
              required
              className="auth-input"
              minLength="8"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('passwordReset.confirmPassword')}
              required
              className="auth-input"
              minLength="8"
            />
          </div>

          <button type="submit" className="auth-button">
            {t('passwordReset.resetPassword')}
          </button>

          <button
            type="button"
            onClick={() => navigate('/login')}
            className="auth-button secondary"
          >
            {t('backToLogin')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword; 